import type { VisitedPlaces } from "./VisitedPlaces";
import { UIQuery } from "./UIQuery";
import am5geodata_data_countries2 from "@amcharts/amcharts5-geodata/data/countries2";

export class Sharing {

  public visited: number = 0;
  public total: number = 0;
  public percent: number = 0;
  public placeName: string = "country";
  public placeNameMany: string = "countries";
  public description: string = "";
  public url: string = "";
  public image: string = "";

  protected _visitedPlaces!: VisitedPlaces;
  protected _exporting!: VisitedPlaces;

  static new(visitedPlaces: VisitedPlaces): Sharing {
    const x = new this(visitedPlaces, true);
    x._afterNew();
    return x;
  }

  constructor(visitedPlaces: VisitedPlaces, isReal: boolean) {
    if (!isReal) {
      throw new Error("You cannot use `new Class()`, instead use `Class.new()`");
    }

    this._visitedPlaces = visitedPlaces;
  }

  protected _afterNew() {
    // Sharing
    UIQuery.find(".download").on("click", (target: any) => {
      this.download(target.getAttribute("data-download"));
    });

    UIQuery.find(".share").on("click", (target: any) => {
      const label = UIQuery.create("span").appendTo(target).html("Opening...");
      setTimeout(() => {
        this.share(target.getAttribute("data-share"));
        label.remove();
      }, 100);
    });

    UIQuery.find("#exportvideo").on("click", () => {
      window.location.href = window.location.href.replace(/\/[a-z_]*\/\?/i, "/video/?");
    });

    UIQuery.find("#saveshot").on("click", () => {
      this.generateScreenshot();
    });

    if (!this._visitedPlaces.worldMap) {
      this.placeName = "place";
      this.placeNameMany = "places";
    }

  }

  public resetScreenshot() {
    this.loadImage().then(async (resp) => {
      const data = await resp.json();
      if (data.image != '') {
        this.image = data.image;
        this.updateSnapshot();
      }
      else {
        this.updateSnapshot(true);
      }
    });
  }

  public download(format: string) {
    if (format == "png" || format == "jpg") {
      this._visitedPlaces.toggleTimeline(true);
      this._visitedPlaces.exporting.download(format).then(() => {
        this._visitedPlaces.toggleTimeline();
      });
      return;
    }

    if (format == "html") {
      const uri = "data:text/html;utf-8," + encodeURIComponent(this.getPage());
      this.stream(uri);
    }
  }

  public stream(uri: string) {
    const exporting = this._visitedPlaces.exporting;
    exporting.streamFile(uri, exporting.get("filePrefix", "MyVisitedPlaces") + ".html");
  }

  public async generateScreenshot() {
    UIQuery.find("#saveshot").removeClass("btn-idle").addClass("btn-working");
    setTimeout(async () => {
      let imgData = await this.getImage();
      const imageResp = await this.saveImage(imgData);
      const imageRespData = await imageResp.json();
      this.image = imageRespData.image;
      this.updateSnapshot();
      UIQuery.find("#saveshot").removeClass("btn-working").addClass("btn-idle");
    }, 100);
  }

  public updateSnapshot(empty: boolean = false) {
    UIQuery.find("#snapshot").prop("src", empty ? "/lib/share_empty.png" : "/share/" + this.image);
  }

  public async share(network: string) {
    this._visitedPlaces.toggleTimeline(true);
    this.updateCounts();
    if (!this.image) {
      let imgData = await this.getImage();
      //imgData = imgData.replace("data:image/png;base64,", "");
      const imageResp = await this.saveImage(imgData);
      const imageRespData = await imageResp.json();
      this.image = imageRespData.image;
      this.updateSnapshot();
    }
    const url = this.getShareURL(network);
    if (url) {
      this.openShareWin(url);
    }
    this._visitedPlaces.toggleTimeline();
  }

  public saveImage(imgData: string): Promise<any> {
    const fd = new FormData();
    fd.append("image", imgData)
    fd.append("config", window.location.search);
    if (this.image) {
      fd.append("prev", this.image);
    }
    return fetch("/view/save/", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      // headers: {
      //   "Content-Type": "application/json"
      // },
      body: fd
    });
  }

  public loadImage(): Promise<any> {
    const fd = new FormData();
    fd.append("config", window.location.search);
    return fetch("/view/save/", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: fd
    });
  }

  public getImage(): Promise<any> {
    return this._visitedPlaces.exporting.export("png");
  }

  public updateCounts() {
    this.visited = 0;
    this._visitedPlaces.data.forEach((step) => {
      this.visited += step.places.length;
    });
    this.total = this._visitedPlaces.polygonSeries.dataItems.length;
    this.percent = Math.round((this.visited / this.total) * 1000) / 10;
    const name = this.visited == 1 ? this.placeName : this.placeNameMany;
    if (this._visitedPlaces.worldMap) {
      this.description = 'I visited ' + this.visited + ' ' + name + ' out of ' + this.total + '. That\'s ' + this.percent + '% of all countries in the world!';
      this.description += " #visitedcountries";
    }
    else {
      this.description = 'I visited ' + this.visited + ' ' + name + ' out of ' + this.total + '.';
      const country = this.getCountryByMap(this._visitedPlaces.map);
      if (country) {
        this.description += ' That\'s ' + this.percent + '% of ' + country + '!'
        this.description += " #" + country.replace(" ", "");
      }
    }
    this.description += " #mytravels #travelmap";
    this.url = this.getViewURL();
  }

  public getCountryByMap(map: string): string | undefined {
    let country = undefined;
    Object.keys(am5geodata_data_countries2).forEach((key: string) => {
      const item = am5geodata_data_countries2[key];
      if (item.maps.indexOf(map + "Low") !== -1) {
        country = item.country;
      }
    })
    return country;
  }

  // public updateHeaders() {
  //   this.updateCounts();
  //   if (this.visited > 0) {
  //     UIQuery.find("meta[property='og:description']").attr("content", this.description);
  //     UIQuery.find("meta[property='og:url']").attr("content", this.url);
  //   }
  // }

  public getViewURL(): string {
    let url = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
    return url + "/view/" + window.location.search;
  }

  public getEmbedURL(): string {
    let url = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
    return url + "/embed/" + window.location.search;
  }

  public getIframe(): string {
    return "<iframe src=\"" + this.getEmbedURL() + "\" style=\"width: 100%; height: 600px;\"></iframe>";
  }

  public getPage(): string {
    // @todo image
    let html = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <title>My visited places | VisitedPlaces.com</title>
    <meta name="apple-mobile-web-app-capable" content="yes">
    <meta name="apple-mobile-web-app-status-bar-style" content="white">
    <meta name="viewport" content="width=device-width, minimum-scale=1, initial-scale=1, user-scalable=yes, minimal-ui">

    <meta name="description" content="${this.description}">
    <meta name="keywords" content="maps, travel, visited countries, charts, visual">

    <meta name="twitter:card" content="photo" />
    <meta name="twitter:site" content="@visitedplaces" />
    <meta name="twitter:image" content="https://www.visitedplaces.com/lib/index.png" />

    <meta property="og:title" content="Interactive Visited Places Map" />
    <meta property="og:site_name" content="VisitedPlaces.com" />
    <meta property="og:url" content="https://www.visitedplaces.com/" />
    <meta property="og:description" content="${this.description}" />
    <meta property="og:type" content="article" />
    <meta property="og:image" content="https://www.amcharts.com/inspiration/images/shots/index.jpg" />
    <style>
      body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        padding: 0;
        margin: 0;
      }
    </style>
  </head>
  <body>
    ${this.getHTML()}
  </body>
</html>`;
    return html;
  }

  public getHTML(): string {
    const vp = this._visitedPlaces;
    const width = vp.width ? vp.width + "px" : "100%";
    const height = vp.height ? vp.height + "px" : "600px";
    return `<!-- VisitedPlaces.com code -->
<script>
// VisitedPlaces.com code
var visitedplaces_config = ${JSON.stringify(this._visitedPlaces.getConfig(), undefined, "  ")};
</script>
<div id="chartdiv" style="width: ${width}; height: ${height};">
<script src="https://www.visitedplaces.com/js/common.js"></script>
<script src="https://www.visitedplaces.com/js/viewer.js"></script>
<!-- end: VisitedPlaces.com code -->
`;
  }

  public getShareURL(network: string): string | undefined {
    // https://www.facebook.com/share.php?u=http%3A%2F%2Flocalhost%3A8080%2Feditor.html%23projection%3DgeoNaturalEarth1%26theme%3Dlight-green%26width%3D1416%26height%3D602%26water%3D1%26graticule%3D0%26names%3D1%26duration%3D2500%26position%3D0%26autoplay%3D0%26autozoom%3Dnone%26home%3DLT%26places%3D%3ACY%3A
    // https://twitter.com/intent/tweet?url=http%3A%2F%2Flocalhost%3A8080%2Feditor.html%23projection%3DgeoNaturalEarth1%26theme%3Dlight-green%26width%3D1416%26height%3D602%26water%3D1%26graticule%3D0%26names%3D1%26duration%3D2500%26position%3D0%26autoplay%3D0%26autozoom%3Dnone%26home%3DLT%26places%3D%3ACY%3A&text=Visited%20places%20map%20editor%20-%20Make%20your%20own%20interactive%20countries%20or%20visited%20places%20map.%20Select%2C%20configure%2C%20share%2C%20download.&via=amCharts
    // https://pinterest.com/pin/create/button/?url=http%3A%2F%2Flocalhost%3A8080%2Feditor.html%23projection%3DgeoNaturalEarth1%26theme%3Dlight-green%26width%3D1416%26height%3D602%26water%3D1%26graticule%3D0%26names%3D1%26duration%3D2500%26position%3D0%26autoplay%3D0%26autozoom%3Dnone%26home%3DLT%26places%3D%3ACY%3A&description=Make%20your%20own%20interactive%20countries%20or%20visited%20places%20map.%20Select%2C%20configure%2C%20share%2C%20download.&media=https%3A%2F%2Fwww.amcharts.com%2Finspiration%2Fimages%2Fshots%2Findex.jpg
    // https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Flocalhost%3A8080%2Feditor.html%23projection%3DgeoNaturalEarth1%26theme%3Dlight-green%26width%3D1416%26height%3D602%26water%3D1%26graticule%3D0%26names%3D1%26duration%3D2500%26position%3D0%26autoplay%3D0%26autozoom%3Dnone%26home%3DLT%26places%3D%3ACY%3A&title=Visited%20places%20map%20editor&summary=Make%20your%20own%20interactive%20countries%20or%20visited%20places%20map.%20Select%2C%20configure%2C%20share%2C%20download.
    //console.log(this.url);
    let url: string = "";
    const params: any = {};
    switch (network) {
      case "facebook":
        url = "https://www.facebook.com/share.php";
        //url = "https://www.facebook.com/sharer/sharer.php?";
        params.u = this.url + "&i=" + this.image;
        break;
      case "twitter":
        url = "https://twitter.com/intent/tweet";
        params.url = this.url;
        params.text = this.description;
        params.via = "visitedplaces";
        break;
      case "pinterest":
        url = "https://pinterest.com/pin/create/button/";
        params.url = this.url;
        params.description = this.description;
        params.image_url = this.image;
        break;
      case "linkedin":
        url = "https://www.linkedin.com/shareArticle";
        params.mini = "true";
        params.url = this.url;
        params.title = "My visited places map";
        params.summary = this.description;
        break;
      case "reddit":
        url = "https://www.reddit.com/submit";
        params.url = this.url;
        params.title = "My visited places map";
        params.text = this.description;
        params.media = this.image;
        break;
    }

    const querystring: string[] = [];
    Object.keys(params).forEach(function(key) {
      querystring.push(key + "=" + encodeURIComponent(params[key]));
    });
    url += "?" + querystring.join("&");

    return url;
  }

  public openShareWin(url: string) {
    const clientWidth = document.documentElement.clientWidth;
    const clientHeight = document.documentElement.clientHeight;
    const width = Math.min(clientWidth * 0.8, 800);
    const height = Math.min(clientHeight * 0.8, 800);
    const left = (clientWidth / 2 - width / 2);
    const top = (clientHeight - height) / 2;
    const options = "status=1,resizable=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left;
    window.open(url, '', options)!.focus();
  }

}