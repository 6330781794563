export class UIQuery {

  public static find(query: string): UIQuery {
    return new UIQuery(document.querySelectorAll(query));
  }

  public static findNode(target: HTMLElement, query: string): UIQuery {
    return new UIQuery(target.querySelectorAll(query));
  }

  public static envelope(element: Element): UIQuery {
    return new UIQuery([element]);
  }

  public static create(tag: string): UIQuery {
    return new UIQuery([document.createElement(tag)]);
  }

  public nodes: NodeList | Array<Element>;

  constructor(nodes: NodeList | Array<Element>) {
    this.nodes = nodes;
  }

  public each(callback: (item: Node) => unknown): UIQuery {
    this.nodes.forEach((node: any) => callback.call(this, node));
    return this;
  }

  public on(event: string, callback: (target?: any, event?: any) => unknown, options?: any): UIQuery {
    this.nodes.forEach((node: any) => {
      node.addEventListener(event, (ev: any) => callback.call(node, node, ev), options)
    });
    return this;
  }

  public attr(name: string, value?: any): any {
    if (value !== undefined) {
      this.nodes.forEach((node: any) => node.setAttribute(name, "" + value));
    }
    else {
      const element = this.firstNode();
      return element ? element.getAttribute(name) : undefined;
    }
    return this;
  }

  public prop(name: string, value?: any): any {
    if (value !== undefined) {
      this.nodes.forEach((node: any) => node[name] = value);
    }
    else {
      const element: any = this.firstNode();
      return element ? element[name] : undefined;
    }
    return this;
  }

  public val(value?: any): any {
    if (value === undefined) {
      const element = this.firstNode() as any;
      return element ? element.value : undefined;
    }
    else {
      this.each((item) => (item as HTMLInputElement).value = value + "");
      return this;
    }
  }

  public html(html?: string | number): any {
    if (html === undefined) {
      const element = this.firstNode() as any;
      return element ? element.innerHTML : undefined;
    }
    else {
      this.each((item) => (item as Element).innerHTML = html + "");
      return this;
    }
  }

  public remove() {
    this.each((element: any) => {
      if (element.parentElement) {
        element.parentElement.removeChild(element);
      }
    });
  }

  public appendTo(target: HTMLElement): any {
    this.each((element: any) => {
      target.appendChild(element);
    });
    return this;
  }

  public firstNode(): Element | undefined {
    return this.nodes.length ? this.nodes[0] as Element : undefined;
  }

  public first(): UIQuery {
    return new UIQuery(this.nodes.length ? [this.nodes[0] as Element] : []);
  }

  public lastNode(): Element | undefined {
    return this.nodes.length ? this.nodes[this.nodes.length - 1] as Element : undefined;
  }

  public last(): UIQuery {
    return new UIQuery(this.nodes.length ? [this.nodes[this.nodes.length - 1] as Element] : []);
  }

  public addClass(className: string): any {
    this.each((element: any) => {
      if (!element) {
        return;
      }
      if (element.classList) {
        const classes = className.split(" ");
        classes.forEach((name: string) => {
          element.classList.add(name);
        });
      }
      else {
        let currentClassName = element.getAttribute("class");
        if (currentClassName) {
          element.setAttribute("class", currentClassName.split(" ").filter((item: any) => {
            return item !== className;
          }).join(" ") + " " + className);
        }
        else {
          element.setAttribute("class", className);
        }
      }
    });
    return this;
  }

  public removeClass(className: string): any {
    this.each((element: any) => {
      if (!element) {
        return;
      }
      if (element.classList) {
        element.classList.remove(className);
      }
      else {
        let currentClassName = element.getAttribute("class");
        if (currentClassName) {
          element.setAttribute("class", currentClassName.split(" ").filter((item: any) => {
            return item !== className;
          }).join(" "));
        }
      }
    });
    return this;
  }

  public show(): any {
    this.each((element: any) => {
      element.style.display = "";
    });
    return this;
  }

  public hide(): any {
    this.each((element: any) => {
      element.style.display = "none";
    });
    return this;
  }

}