declare const bootstrap: any;

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5map from "@amcharts/amcharts5/map";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";

import am5geodata_continentsLow from "@amcharts/amcharts5-geodata/continentsLow";

export class Banner {

  static new(): Banner {
    const x = new this(true);
    x._afterNew();
    return x;
  }

  constructor(isReal: boolean) {
    if (!isReal) {
      throw new Error("You cannot use `new Class()`, instead use `Class.new()`");
    }
  }

  protected _afterNew() {

    var root = am5.Root.new("introchart");
    root.container.set("minWidth", 400);

    const myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fontSize: 9
    });

    root.setThemes([am5themes_Animated.new(root), am5themes_Dark.new(root), myTheme]);

    var previousSelectedId: any;
    var currentIndex: number = -1;
    var selectCountryInterval: any;
    var colorSet = am5.ColorSet.new(root, {})
    var continents = ["europe", "asia", "northAmerica", "southAmerica", "oceania", "africa"];
    var continentNames: any = { europe: "Europe", asia: "Asia", northAmerica: "North America", southAmerica: "South America", oceania: "Oceania", africa: "Africa" };

    var lineSeries: any = {};
    var radarRangeFills: any = {};
    var polygonSeries: any;
    var mapChart: any;
    var gaugeSeries: any;
    var radarGauge: any;
    var globeContainer: any;

    var colors: any = {
      europe: colorSet.next(),
      asia: colorSet.next(),
      northAmerica: colorSet.next(),
      southAmerica: colorSet.next(),
      oceania: colorSet.next(),
      africa: colorSet.next(),
      antarctica: am5.color(0x444444)
    }


    var categoryAxis: any;
    var continentsData = [
      { name: "Europe", id: "europe", count: 20 },
      { name: "Asia", id: "asia", count: 30 },
      { name: "North America", id: "northAmerica", count: 10 },
      { name: "South America", id: "southAmerica", count: 15 },
      { name: "Oceania", id: "oceania", count: 10 },
      { name: "Africa", id: "africa", count: 25 }
    ]
    var continentStartCategory: any = {};
    var continentEndCategory: any = {};

    makeLineSeriesChart();

    var mainContainer = root.container.children.push(am5.Container.new(root, {
      height: am5.p100,
      width: am5.p100,
      layout: root.horizontalLayout
    }))

    mainContainer.events.on("boundschanged", function() {
      var width = mainContainer.width();
      if (width < 1000) {
        radarGauge.hide();
        if (width < 400) {
          globeContainer.hide(0);
        }
        else {
          if (globeContainer.isHidden()) {
            globeContainer.show(0);
          }
        }
      }
      else {
        if (radarGauge.isHidden()) {
          radarGauge.show();
        }
      }
    })

    var bg = am5.Rectangle.new(root, {
      fillOpacity: 0.0, fill: am5.color(0xff0000), cursorOverStyle: "pointer"
    })

    var navContainer = mainContainer.children.push(am5.Container.new(root, {
      height: am5.p100,
      width: am5.p100,
      minWidth: 400,
      centerY: am5.p50,
      y: am5.p50,
      layout: root.horizontalLayout,
      background: bg,
      setStateOnChildren: true
    }))

    navContainer.events.on("click", function() {
      window.location.href = "https://www.amcharts.com"
    })

    navContainer.events.on("pointerover", function() {
      label.hover();
    })

    navContainer.events.on("pointerout", function() {
      label.unhover();
    })


    var navChartsContainer = navContainer.children.push(am5.Container.new(root, {
      height: am5.p100,
      centerY: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      x: am5.p50,
      layout: root.horizontalLayout
    }))


    var label = navChartsContainer.children.push(am5.Label.new(root, {
      text: "Looking for a new\ndata-viz library? Try",
      fontSize: "1.5em",
      opacity: 0.7,
      centerY: am5.p50,
      y: am5.p50
    }))

    label.states.create("hover", {
      opacity: 1
    })

    label.states.create("default", {
      opacity: .7
    })

    const logo = navChartsContainer.children.push(am5.Container.new(root, {
      setStateOnChildren: true,
      paddingTop: 9,
      paddingRight: 9,
      paddingBottom: 9,
      paddingLeft: 9,
      scale: 1.4,
      dy: -11,
      y: am5.p50,
      marginLeft: -15,
      centerY: am5.p50
    }));

    logo.states.create("hover", {});

    const m = logo.children.push(am5.Graphics.new(root, {
      stroke: am5.color(0xffffff),
      strokeWidth: 3,
      svgPath: "M5 25 L13 25h13.6c3.4 0 6 0 10.3-4.3s5.2-12 8.6-12c3.4 0 4.3 8.6 7.7 8.6M83.4 25H79.8c-3.4 0-6 0-10.3-4.3s-5.2-12-8.6-12-4.3 8.6-7.7 8.6"
    }));

    m.states.create("hover", { stroke: am5.color(0x3CABFF) });

    logo.children.push(am5.Graphics.new(root, {
      stroke: am5.color(0x72acfe),
      strokeWidth: 3,
      svgPath: "M83.4 25h-31C37 25 39.5 4.4 28.4 4.4S18.9 24.2 4.3 25H0"
    }));

    logo.children.push(am5.Label.new(root, {
      text: "AM[Bold #ffffff]CHARTS[/]",
      fontSize: 10.5,
      fontFamily: "Verdana",
      dy: 20
    }))





    var container = mainContainer.children.push(am5.Container.new(root, {
      height: am5.p100,
      layout: root.horizontalLayout
    }))

    function makeRadarChart() {
      globeContainer = container.children.push(am5.Container.new(root, {
        height: 200,
        width: 200,
        marginRight: 100,
        marginLeft: 50,
        centerY: am5.p50,
        y: am5.p50
      }))

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      var chart = globeContainer.children.push(am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        innerRadius: 128,
        radius: 180
      }));

      chart.radarContainer.children.setIndex(0, am5.Circle.new(root, {
        radius: 125,
        fillOpacity: 1,
        fill: am5.color(0x111111)
      }))

      chart.zoomOutButton.set("forceHidden", true);

      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      var xRenderer = am5radar.AxisRendererCircular.new(root, {
        strokeOpacity: 0
      });

      xRenderer.labels.template.setAll({
        forceHidden: true
      });

      xRenderer.grid.template.setAll({
        forceHidden: true
      });

      var yRenderer = am5radar.AxisRendererRadial.new(root, {
        strokeOpacity: 0
      });

      yRenderer.labels.template.setAll({
        forceHidden: true
      });

      yRenderer.grid.template.setAll({
        forceHidden: true
      });

      categoryAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "category",
        renderer: xRenderer
      }));

      var valueAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: yRenderer
      }));

      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      var series = chart.series.push(am5radar.RadarColumnSeries.new(root, {
        xAxis: categoryAxis,
        yAxis: valueAxis,
        stroke: am5.color(0xffffff),
        fill: am5.color(0xffffff),
        valueYField: "value",
        categoryXField: "category"
      }));

      series.columns.template.setAll({
        fillOpacity: 0.1,
        strokeOpacity: 0,
        cornerRadius: 5
      })

      var lineSeries = chart.series.push(am5radar.RadarLineSeries.new(root, {
        xAxis: categoryAxis,
        yAxis: valueAxis,
        valueYField: "value2",
        categoryXField: "category"
      }));

      lineSeries.strokes.template.setAll({
        forceHidden: true
      })

      lineSeries.bullets.push(function() {
        var sprite = am5.Circle.new(root, {
          radius: 4 * Math.random(), fill: lineSeries.get("stroke")
        })

        return am5.Bullet.new(root, {
          sprite: sprite
        })
      })

      var i = 0;
      // Generate and set data
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data
      var data = generateRadarData();

      series.data.setAll(data);
      lineSeries.data.setAll(data);
      categoryAxis.data.setAll(data);

      series.appear(2000);
      lineSeries.appear(2000);

      function createRange(id: string, startCategory: string, endCategory: string) {
        var axisRange = categoryAxis.createAxisRange(categoryAxis.makeDataItem({ above: true }));
        // first country
        axisRange.set("category", startCategory);
        // last country
        axisRange.set("endCategory", endCategory);

        // every 3rd color for a bigger contrast
        var fill = axisRange.get("axisFill");
        fill.setAll({
          toggleKey: "active",
          cursorOverStyle: "pointer",
          fill: chart.get("colors").getIndex(i),
          stroke: am5.color(0x000000),
          strokeOpacity: 0,
          visible: true,
          innerRadius: 110,
          radius: 127,
          fillOpacity: 0.8
        });

        radarRangeFills[id] = fill;
        i++;

        continentStartCategory[id] = startCategory;
        continentEndCategory[id] = endCategory;

        axisRange.get("grid").set("visible", false);

        var label = axisRange.get("label");
        label.setAll({
          forceHidden: false,
          fontSize: 9,
          fill: am5.color(0xffffff),
          textType: "circular",
          opacity: .8,
          radius: -65,
          text: continentNames[id],
          interactive: false
        });

        fill.states.create("hover", {
          fillOpacity: 1,
          innerRadius: 105
        })

        fill.events.on("pointerover", function(_event: any) {
          hoverCountry(id)
        })

        fill.events.on("pointerout", function(_event: any) {
          unhoverCountry(id)
        })

        fill.events.on("click", function(_event: any) {
          clearInterval(selectCountryInterval);
          //var dataItem = event.target.dataItem;
          if (previousSelectedId == id) {
            unselectContinent();
          }
          else {
            selectContinent(id)
          }
        });
      }

      function generateRadarData() {
        var data: any[] = [];
        var c = 0;
        i = 0;
        am5.array.each(continentsData, function(obj) {
          var first = c;
          for (var i = 0; i < obj.count; i++) {
            data.push({ category: "c" + c, value: Math.random(), value2: Math.random() * 0.5 + .2 })
            c++;
          }

          createRange(obj.id, "c" + first, "c" + (c - 1));
        })

        return data;
      }
    }



    function makeMapChart() {
      // Create the map chart
      // https://www.amcharts.com/docs/v5/charts/map-chart/
      mapChart = globeContainer.children.push(am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "rotateY",
        wheelX: "none",
        wheelY: "none",
        height: 200,
        width: 200,
        centerY: am5.p50,
        y: am5.p50,
        projection: am5map.geoOrthographic(),
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0
      }));

      mapChart.chartContainer.set("background", am5.Circle.new(root, {
        themeTags: ["map", "background"],
        fill: am5.color(0x222222),
        fillOpacity: 0,
        radius: 100,
        dx: 100,
        dy: 100
      }));

      // Create series for background fill
      // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
      var graticuleSeries = mapChart.series.push(am5map.GraticuleSeries.new(root, {}));
      graticuleSeries.mapLines.template.setAll({
        stroke: root.interfaceColors.get("alternativeBackground"),
        strokeOpacity: 0.04
      });

      // Create main polygon series for countries
      // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
      polygonSeries = mapChart.series.push(am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_continentsLow
      }));

      polygonSeries.mapPolygons.template.setAll({
        toggleKey: "active",
        interactive: true,
        fillOpacity: 0.7,
        strokeOpacity: 0,
        cursorOverStyle: "pointer"
      });

      polygonSeries.mapPolygons.template.adapters.add("fill", function(fill: any, target: any) {
        if (target.dataItem) {
          var color = colors[target.dataItem.dataContext.id];
          target.set("stroke", color)
          return color;
        }
        return fill
      })

      polygonSeries.mapPolygons.template.states.create("hover", {
        fillOpacity: 1
      });

      polygonSeries.mapPolygons.template.states.create("active", {
        fillOpacity: 1
      });

      polygonSeries.mapPolygons.template.states.create("dimm", {
        fillOpacity: 0.5
      });

      polygonSeries.mapPolygons.template.events.on("pointerover", function(e: any) {
        hoverCountry(e.target.dataItem.get("id"))
      })

      polygonSeries.mapPolygons.template.events.on("pointerout", function(e: any) {
        unhoverCountry(e.target.dataItem.get("id"))
      })

      // Set up events
      var previousPolygon: any;

      polygonSeries.mapPolygons.template.on("active", function(_active: boolean, target: any) {

        clearInterval(selectCountryInterval);

        if (previousPolygon && previousPolygon != target) {
          previousPolygon.set("active", false);
        }
        if (target.get("active")) {
          selectContinent(target.dataItem.get("id"));
        }
        else {
          unselectContinent();
        }
        previousPolygon = target;
      });

      // Make stuff animate on load
      mapChart.appear(1000, 1000);
    }


    function selectContinent(id: string) {
      if (previousSelectedId) {
        unhoverCountry(previousSelectedId);
      }
      hoverCountry(id, true);
      var dataItem = polygonSeries.getDataItemById(id);
      var target = dataItem.get("mapPolygon");
      if (target) {
        var centroid = target.geoCentroid();
        if (centroid) {
          mapChart.animate({ key: "rotationX", to: -centroid.longitude, duration: 1500, easing: am5.ease.inOut(am5.ease.cubic) });
          mapChart.animate({ key: "rotationY", to: -centroid.latitude, duration: 1500, easing: am5.ease.inOut(am5.ease.cubic) });
        }
      }


      am5.array.each(polygonSeries.dataItems, function(dataItem: any) {
        if (dataItem.dataContext.id != id) {
          dataItem.get("mapPolygon").states.applyAnimate("dimm");
        }
        else {
          dataItem.get("mapPolygon").states.applyAnimate("active");
        }
      })

      if (previousSelectedId) {
        radarRangeFills[previousSelectedId].set("active", false);
      }


      categoryAxis.zoomToCategories(continentStartCategory[id], continentEndCategory[id]);
      currentIndex = continents.indexOf(id);

      previousSelectedId = id;
    }


    selectCountryInterval = setInterval(function() {
      currentIndex++;

      if (currentIndex >= continents.length) {
        clearInterval(selectCountryInterval)

        setTimeout(function() {
          var animation = mapChart.animate({
            key: "rotationX",
            to: 0,
            duration: 3000,
            easing: am5.ease.cubic
          });


          animation.events.on("stopped", function() {
            mapChart.animate({
              key: "rotationX",
              to: 360,
              from: 0,
              duration: 30000,
              loops: Infinity
            });
          })

        }, 1000)

        unselectContinent();
      }
      else {
        selectContinent(continents[currentIndex])
      }
    }, 4000)


    function makeRadarGauge() {

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      radarGauge = container.children.push(am5radar.RadarChart.new(root, {
        width: 230,
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        innerRadius: am5.percent(20),
        startAngle: -90,
        endAngle: 180,
        paddingRight: 50,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20
      }));

      radarGauge.states.create("hidden", {
        width: 1,
        opacity: 0,
        visible: false
      })

      // Data
      var data: any[] = [];

      am5.array.each(continents, function(id) {
        data.push({
          category: continentNames[id],
          value: Math.round(Math.random() * 70) + 20,
          full: 100,
          id: id,
          settings: {
            fill: colors[id]
          }
        })
      })


      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      var xRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 20
      });

      xRenderer.labels.template.setAll({
        radius: 10,
        opacity: 0.3
      });

      xRenderer.grid.template.setAll({
        forceHidden: true
      });

      var xAxis = radarGauge.xAxes.push(am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
        numberFormat: "#'%'"
      }));

      //xRenderer.labels.template.set("forceHidden", true)


      var yRenderer = am5radar.AxisRendererRadial.new(root, {
        minGridDistance: 20
      });

      yRenderer.labels.template.setAll({
        forceHidden: true
      });

      yRenderer.grid.template.setAll({
        forceHidden: true
      });

      var yAxis = radarGauge.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer
      }));

      yAxis.data.setAll(data);

      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      var series1 = radarGauge.series.push(am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "full",
        categoryYField: "category",
        fill: root.interfaceColors.get("alternativeBackground")
      }));

      series1.columns.template.setAll({
        width: am5.p100,
        fillOpacity: 0.1,
        strokeOpacity: 0,
        cornerRadius: 20
      });

      series1.data.setAll(data);

      gaugeSeries = radarGauge.series.push(am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "value",
        categoryYField: "category"
      }));

      gaugeSeries.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0,
        cornerRadius: 20,
        templateField: "settings",
        cursorOverStyle: "pointer"
      });

      gaugeSeries.columns.template.states.create("dimm", {
        opacity: 0.4
      })

      gaugeSeries.columns.template.events.on("pointerover", function(event: any) {
        hoverCountry(event.target.dataItem.dataContext.id, true)
      })

      gaugeSeries.columns.template.events.on("pointerout", function(event: any) {
        unhoverCountry(event.target.dataItem.dataContext.id)
      })

      gaugeSeries.columns.template.events.on("click", function(event: any) {
        clearInterval(selectCountryInterval);
        //var dataItem = event.target.dataItem;
        var id = event.target.dataItem.dataContext.id;
        if (previousSelectedId == id) {
          unselectContinent();
        }
        else {
          selectContinent(id)
        }
      })

      gaugeSeries.data.setAll(data);

      // Animate chart and series in
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      gaugeSeries.appear(1000, 2000);
    }


    function makeLineSeriesChart() {
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var lineSeriesChart = root.container.children.push(
        am5xy.XYChart.new(root, {})
      );

      // The data
      var data = [
        {
          year: "1930",
          europe: 0,
          asia: 0,
          northAmerica: 0,
          southAmerica: 0,
          oceania: 0,
          africa: 0
        },
        {
          year: "1934",
          europe: 0,
          asia: 0,
          northAmerica: 0,
          southAmerica: 0,
          oceania: 0,
          africa: 0
        },
        {
          year: "1938",
          europe: 1,
          asia: 0,
          northAmerica: -1,
          southAmerica: 1.5,
          oceania: 1.5,
          africa: -2
        },
        {
          year: "1950",
          europe: 3,
          asia: -4,
          northAmerica: 1,
          southAmerica: -3,
          oceania: -1.5,
          africa: 0.5
        },
        {
          year: "1954",
          europe: -1,
          asia: 1,
          northAmerica: -2,
          southAmerica: 4,
          oceania: .5,
          africa: -2.5
        },
        {
          year: "1958",
          europe: 2,
          asia: -4,
          northAmerica: 3,
          southAmerica: -3.5,
          oceania: -2,
          africa: 5
        },
        {
          year: "1962",
          europe: -2,
          asia: 4,
          northAmerica: -5,
          southAmerica: 6,
          oceania: 1,
          africa: -4.5
        },
        {
          year: "1966",
          europe: 2,
          asia: -1,
          northAmerica: 3,
          southAmerica: -3.5,
          oceania: -2,
          africa: 4
        },
        {
          year: "1970",
          europe: -1,
          asia: 1.5,
          northAmerica: -2.5,
          southAmerica: 2,
          oceania: 0.5,
          africa: -2
        },
        {
          year: "1978",
          europe: 0,
          asia: 0,
          northAmerica: 0,
          southAmerica: 0,
          oceania: 0,
          africa: 0
        },
        {
          year: "1982",
          europe: 0,
          asia: 0,
          northAmerica: 0,
          southAmerica: 0,
          oceania: 0,
          africa: 0
        }
      ];

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, {
        inside: true
      });
      xRenderer.labels.template.setAll({ forceHidden: true });
      xRenderer.grid.template.setAll({
        strokeGradient: am5.LinearGradient.new(root, {
          stops: [
            { color: am5.color(0xffffff), opacity: 0 },
            { color: am5.color(0xffffff), opacity: .1 },
            { color: am5.color(0xffffff), opacity: 0 }
          ]
        })

      });

      var xAxis = lineSeriesChart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          startLocation: 0.6,
          endLocation: 0.4,
          categoryField: "year",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      xAxis.data.setAll(data);

      var yRenderer = am5xy.AxisRendererY.new(root, {});
      yRenderer.labels.template.setAll({ forceHidden: true });
      yRenderer.grid.template.setAll({ forceHidden: true });

      var yAxis = lineSeriesChart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          strictMinMax: true,
          min: - 9,
          max: 9,
          renderer: yRenderer
        })
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      function createSeries(id: string) {
        var series = lineSeriesChart.series.push(
          am5xy.SmoothedXLineSeries.new(root, {
            name: id,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: id,
            categoryXField: "year",
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
            }),
            opacity: 0.5
          })
        );
        lineSeries[id] = series;

        series.strokes.template.setAll({
          strokeOpacity: 1,

          strokeGradient: am5.LinearGradient.new(root, {
            rotation: 0,
            stops: [
              { color: series.get("fill"), opacity: 0 },
              { color: series.get("fill"), opacity: 0.4 },
              { color: series.get("fill"), opacity: .9 },
              { color: series.get("fill"), opacity: 0 }
            ]
          })
        })
        series.states.create("default", {
          opacity: 0.6
        })

        series.states.create("highlight", {
          opacity: 1
        })

        series.states.create("dimm", {
          opacity: .4
        })

        series.data.setAll(data);
        series.appear(1000 + Math.random() * 500, Math.random() * 1000);
      }



      createSeries("europe");
      createSeries("asia");
      createSeries("northAmerica");
      createSeries("southAmerica");
      createSeries("oceania");
      createSeries("africa");
    }



    function unselectContinent() {
      categoryAxis.zoom(0, 1);
      if (previousSelectedId) {
        radarRangeFills[previousSelectedId].set("active", false);
        unhoverCountry(previousSelectedId);

        am5.array.each(polygonSeries.dataItems, function(dataItem: any) {
          dataItem.get("mapPolygon").states.applyAnimate("default");
          dataItem.get("mapPolygon").set("active", false);
        })
      }
      previousSelectedId = undefined
    }

    function hoverCountry(id: string, fake: boolean = false) {
      var series = lineSeries[id];
      if (series) {
        series.states.applyAnimate("highlight");

        am5.array.each(series.dataItems, function(dataItem: any) {
          animateDataItem(dataItem, dataItem.get("valueYWorking") * -1, am5.ease.out(am5.ease.cubic), 700)
        })

        am5.object.each(lineSeries, function(_key, s) {
          if (s != series) {
            s.states.applyAnimate("dimm");
          }
        })

        if (!fake) {
          radarRangeFills[id].hover();
        }

        am5.array.each(gaugeSeries.dataItems, function(dataItem: any) {
          if (dataItem.dataContext.id != id) {
            dataItem.get("graphics").states.applyAnimate("dimm");
          }
        })
      }
    }

    function animateDataItem(dataItem: any, toValue: number, ease: any, duration: number) {
      dataItem.animate({ key: "valueYWorking", to: toValue, duration: duration, easing: ease })
    }


    function unhoverCountry(id: string) {
      var series = lineSeries[id];
      if (series) {
        radarRangeFills[id].unhover();

        am5.array.each(series.dataItems, function(dataItem: any) {
          animateDataItem(dataItem, dataItem.get("valueY"), am5.ease.out(am5.ease.cubic), 600)
        })

        am5.object.each(lineSeries, function(_key, s) {
          s.states.applyAnimate("default");
        })

        am5.array.each(gaugeSeries.dataItems, function(dataItem: any) {
          if (dataItem.dataContext.id != id) {
            dataItem.get("graphics").states.applyAnimate("default");
          }
        })
      }
    }


    makeRadarGauge();
    makeRadarChart();
    makeMapChart();
  }

}