import type { VisitedPlaces } from "./VisitedPlaces";
import { UIQuery } from "./UIQuery";

export class CommonUI {

  protected _visitedPlaces!: VisitedPlaces;

  protected _resizing: { x: number, y: number, width: number, height: number, resizing: boolean } = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    resizing: false
  };

  static new(visitedPlaces: VisitedPlaces): CommonUI {
    const x = new this(visitedPlaces, true);
    x._afterNew();
    return x;
  }

  constructor(visitedPlaces: VisitedPlaces, isReal: boolean) {
    if (!isReal) {
      throw new Error("You cannot use `new Class()`, instead use `Class.new()`");
    }

    this._visitedPlaces = visitedPlaces;
  }

  protected _afterNew() {
    const vp = this._visitedPlaces;
    vp.chart.onPrivate("width", (_width) => {
      if (!vp.width && !vp.height) {
        this.setMapSize();
      }
    });

    UIQuery.find("#resizer").on("mousedown", (_target, ev) => {
      const div = UIQuery.find("#resizable").firstNode()!;
      const bbox = div.getBoundingClientRect();
      this._resizing.x = ev.clientX;
      this._resizing.y = ev.clientY;
      this._resizing.width = bbox.width;
      this._resizing.height = bbox.height;
      this._resizing.resizing = true;
    });

    UIQuery.find("#size").on("change", (target: HTMLSelectElement) => {
      const size = UIQuery.envelope(target).val();
      const dims = size.split("x");
      if (dims.length == 2) {
        this.setMapSize(dims[0], dims[1], false);
      }
      else if (size == "") {
        this.setMapSize();
        UIQuery.find("#size").val("");
      }
      else {
        this.setMapSize();
      }
    });

    document.documentElement.addEventListener("mousemove", (ev) => {
      if (this._resizing.resizing) {
        const w = this._resizing.width + (ev.clientX - this._resizing.x) * 2;
        const h = this._resizing.height + ev.clientY - this._resizing.y;
        this.setMapSize(w, h, true);
      }
    });
    document.documentElement.addEventListener("mouseup", (ev) => {
      if (this._resizing.resizing) {
        const w = this._resizing.width + (ev.clientX - this._resizing.x) * 2;
        const h = this._resizing.height + ev.clientY - this._resizing.y;
        this.setMapSize(w, h, true);
        this._resizing.resizing = false;
      }
    });
  }

  public setMapSize(w?: number, h?: number, custom: boolean = false) {
    const vp = this._visitedPlaces;
    const div = UIQuery.find("#resizable").firstNode()! as HTMLDivElement;
    const size = UIQuery.find("#size");
    const customsize = UIQuery.find("#customsize");
    const maxw = window.innerWidth;
    const maxh = window.innerHeight * .7;

    if (w && h) {
      w = Math.round(w);
      h = Math.round(h);
      let ratio = Math.floor(Math.min(maxw / w, maxh / h) * 10) / 10;

      if (ratio < 1) {
        vp.root._renderer.resolution /= ratio;
      }
      else {
        vp.root._renderer.resolution = window.devicePixelRatio;
      }

      div.style.maxWidth = "100vw";
      div.style.width = "" + w + "px";
      div.style.height = "" + h + "px";
      customsize.html("Custom (" + w + "x" + h + ")")
      customsize.val(w + "x" + h);
      if (custom) {
        size.val(w + "x" + h);
      }
    }
    else {
      vp.root._renderer.resolution = window.devicePixelRatio;
      div.style.maxWidth = "";
      div.style.width = "";

      const bbox = div.getBoundingClientRect();
      let w = bbox.width;
      let h = Math.round(w / 1.9);

      if (w > maxw) {
        h *= w / maxw;
        w = maxw;
      }
      if (h > maxh) {
        w *= h / maxh;
        h = maxh;
      }

      div.style.height = h + "px";

    }
    //vp.setSize(w, h);
  }

}