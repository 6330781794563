declare const visitedplaces_config: any;

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
//import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_data_countries2 from "@amcharts/amcharts5-geodata/data/countries2";

export class VisitedPlaces {
  // settings which users might want to edit
  public nameLabelBaseSize = 13;
  public nameLabelFontWeight = "500";
  public baseHeight = 600; // name font size is adjusted using this
  public previouslyVisitedOpacity = 0.6; // opacity of in previous steps visited countries
  public pinRadius = 3; // radius of pins on small areas
  public oneStepAnimationDuration = 600; // when pressing next/previous
  public minZoomLevel = 0.8;
  public zoomStep = 2;
  public placeDuration = 100; // interval at which new visited area is colorized
  public animationDuration = 1500;
  // slider
  public sliderWidth = am5.p100;
  public sliderPaddingLeft = 15;
  public sliderPaddingRight = 32;
  public sliderPaddingBottom = 15;
  protected _appeared = false;
  public _continentSteps: any = {};
  public isEditor = false;

  // various elements
  public map: string = "";
  public worldMap: boolean = true;
  public root!: am5.Root;
  public chart!: am5map.MapChart;
  public zoomControl!: am5map.ZoomControl;
  public background!: am5.Rectangle;
  public waterSeries!: am5map.MapPolygonSeries;
  public polygonSeries!: am5map.MapPolygonSeries;
  public pinSeries!: am5map.MapPointSeries;
  public nameSeries!: am5map.MapPointSeries;
  public graticuleSeries!: am5map.GraticuleSeries;
  public systemTooltipH!: am5.Tooltip;
  public systemTooltipD!: am5.Tooltip;
  public systemTooltipU!: am5.Tooltip;
  public playButton!: am5.Button;
  public nextButton!: am5.Button;
  public prevButton!: am5.Button;
  public replayButton!: am5.Button;
  public visitedLabel!: am5.Label;
  public comLabel!: am5.Label;
  public placesLabel!: am5.Label;
  public bottomContainer!: am5.Container;
  public sliderContainer!: am5.Container;
  public slider!: am5.Slider;
  public sliderBackground!: am5.Graphics;
  public stepLabel!: am5.Label;
  public playButtonOutline!: am5.Circle;
  public saveStepButton!: am5.Button;
  public saveHomeButton!: am5.Button;
  public logoContainer!: am5.Container;
  public homeButton!: am5.Button;
  public buttonTemplate!: am5.Template<am5.RoundedRectangle>;
  public logoPin!: am5.Graphics;
  protected _buttonOutlineAnimation: any;
  public _isPlaying: boolean = false;
  public stepLabelX = 50;
  public resetHomePointButton!: am5.Button;
  public resetStepPointButton!: am5.Button;

  // settings
  public pinThreshold: number = 0.00002;
  public themeName: "dark-yellow" | "dark-green" | "dark-blue" | "dark-gray" | "light-yellow" | "light-green" | "light-blue" | "light-gray" = "dark-green";
  public homeId?: string;
  public width?: number;
  public height?: number;
  public homePolygon?: am5map.MapPolygon;
  public duration: number = 2000;
  public sliderPosition: number = 0;
  public graticuleEnabled: boolean = false;
  public waterEnabled: boolean = true;
  public autoStep: boolean = false;
  public autoHomeStep: boolean = false;
  public namesEnabled: boolean = true;
  public autoPlay: boolean = false;
  public projection: string = "geoNaturalEarth1";
  public autoZoom: "none" | "step" | "all" = "none";

  public pinTemplate!: am5.Template<am5.Circle>;
  public nameLabelTemplate!: am5.Template<am5.Label>;

  public data: Array<{ text?: string, color: string, places: Array<string>, colors: { [index: string]: string }, position?: { zoomLevel: number, geoPoint: am5.IGeoPoint, rotationX: number, rotationY: number } }> = [];
  public homePosition?: { zoomLevel: number, geoPoint: am5.IGeoPoint, rotationX: number, rotationY: number };

  public exporting!: am5plugins_exporting.Exporting;

  protected _polygonAreas: { [index: string]: number } = {}; // measured areas to know where pins should go
  protected _stepDisposers: Array<am5.IDisposer> = [];
  protected _fitLabelsDp?: am5.IDisposer;

  protected _currentStep: number = -1;
  protected _visitedPlaces: Array<string> = []; // all the places visited at least once
  protected _firstVisit: { [index: string]: number } = {}; // step of the first visit of the place
  protected _sliderAnimation: any;
  protected _stepTimes: Array<number> = [];
  protected _replay: boolean = false;

  public homeStepData: any;

  protected _div: string | HTMLDivElement;

  public themes = {
    "dark-yellow": {
      "background": am5.color(0x222222),
      "water": am5.color(0x333333),
      "unvisited": am5.color(0x666666),
      "border": am5.color(0x444444),
      "hover": am5.color(0x777777),
      "label": am5.color(0xffffff),
      "alternativeLabel": am5.color(0xffffff),
      "graticule": am5.color(0x000000),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0xdaaa44),
      "home": am5.color(0xaaba42),
      "hovervisited": am5.color(0xf1c347),
      "sliderOpacity": 0.3,
      "nameLabelOpacity": 0.6
    },
    "dark-green": {
      "background": am5.color(0x222222),
      "water": am5.color(0x333333),
      "unvisited": am5.color(0x666666),
      "border": am5.color(0x444444),
      "hover": am5.color(0x777777),
      "label": am5.color(0xffffff),
      "alternativeLabel": am5.color(0xffffff),
      "graticule": am5.color(0x000000),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0xaaba42),
      "home": am5.color(0xdaaa44),
      "hovervisited": am5.color(0x97ad05),
      "sliderOpacity": 0.3,
      "nameLabelOpacity": 0.6
    },
    "dark-blue": {
      "background": am5.color(0x222222),
      "water": am5.color(0x333333),
      "unvisited": am5.color(0x666666),
      "border": am5.color(0x444444),
      "hover": am5.color(0x777777),
      "label": am5.color(0xffffff),
      "alternativeLabel": am5.color(0xffffff),
      "graticule": am5.color(0x000000),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0x6490a9),
      "home": am5.color(0xcc9f40),
      "hovervisited": am5.color(0x4b99c4),
      "sliderOpacity": 0.3,
      "nameLabelOpacity": 0.6
    },
    "dark-gray": {
      "background": am5.color(0x222222),
      "water": am5.color(0x333333),
      "unvisited": am5.color(0x666666),
      "border": am5.color(0x444444),
      "hover": am5.color(0x777777),
      "label": am5.color(0xffffff),
      "alternativeLabel": am5.color(0xffffff),
      "graticule": am5.color(0x000000),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0xb3b3b3),
      "home": am5.color(0xcc9f40),
      "hovervisited": am5.color(0x97ad05),
      "sliderOpacity": 0.3,
      "nameLabelOpacity": 0.6
    },
    "light-yellow": {
      "background": am5.color(0xffffff),
      "water": am5.color(0xe6e6e6),
      "unvisited": am5.color(0xbbbbbb),
      "border": am5.color(0xffffff),
      "hover": am5.color(0x999999),
      "label": am5.color(0x000000),
      "alternativeLabel": am5.color(0x000000),
      "graticule": am5.color(0x444444),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0xdaaa44),
      "home": am5.color(0xaaba42),
      "hovervisited": am5.color(0xf1c347),
      "sliderOpacity": 0.15,
      "nameLabelOpacity": 0.5
    },
    "light-green": {
      "background": am5.color(0xffffff),
      "water": am5.color(0xe6e6e6),
      "unvisited": am5.color(0xbbbbbb),
      "border": am5.color(0xffffff),
      "hover": am5.color(0x999999),
      "label": am5.color(0x000000),
      "alternativeLabel": am5.color(0x000000),
      "graticule": am5.color(0x444444),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0xaaba42),
      "home": am5.color(0xdaaa44),
      "hovervisited": am5.color(0x97ad05),
      "sliderOpacity": 0.15,
      "nameLabelOpacity": 0.5
    },
    "light-blue": {
      "background": am5.color(0xffffff),
      "water": am5.color(0xe6e6e6),
      "unvisited": am5.color(0xbbbbbb),
      "border": am5.color(0xffffff),
      "hover": am5.color(0x999999),
      "label": am5.color(0x000000),
      "alternativeLabel": am5.color(0x000000),
      "graticule": am5.color(0x444444),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0x6490a9),
      "home": am5.color(0xdaaa44),
      "hovervisited": am5.color(0x4b99c4),
      "sliderOpacity": 0.15,
      "nameLabelOpacity": 0.5
    },
    "light-gray": {
      "background": am5.color(0xffffff),
      "water": am5.color(0xe6e6e6),
      "unvisited": am5.color(0xbbbbbb),
      "border": am5.color(0xffffff),
      "hover": am5.color(0x999999),
      "label": am5.color(0x000000),
      "alternativeLabel": am5.color(0x000000),
      "graticule": am5.color(0x444444),
      "sliderBackground": am5.color(0x000000),
      "visited": am5.color(0xb3b3b3),
      "home": am5.color(0xdaaa44),
      "hovervisited": am5.color(0x97ad05),
      "sliderOpacity": 0.15,
      "nameLabelOpacity": 0.5
    }
  }

  static new<C extends typeof VisitedPlaces, T extends InstanceType<C>>(this: C, div: string): T {
    const x = (new this(div, true)) as T;
    x._afterNew();
    return x;
  }

  constructor(div: string, isReal: boolean) {
    if (!isReal) {
      throw new Error("You cannot use `new Class()`, instead use `Class.new()`");
    }
    this._div = div;

    am5.addLicense("AM5C8151581515");
    am5.addLicense("AM5M8151581515");
  }

  /// public ///////////////////////////////////////////////////////////////////

  /**
   * Sets step of the data
   */
  public setStep(step: number, zoomOut?: boolean) {
    if (this.data.length == 0) {
      return;
    }

    am5.array.each(this._stepDisposers, (disposer) => {
      disposer.dispose();
    })

    this._setStep(step, zoomOut);
    this.slider.set("start", this._stepToPosition(step));
  }

  protected _setStep(step: number, zoomOut?: boolean) {
    this._currentStep = step;
    this._togglePolygons(zoomOut);
    this._toggleButtons();
  }

  public get step(): number {
    return this._currentStep;
  }

  public getEstimatedTime() {
    let duration = this.animationDuration + this._totalDuration();

    if (this.projection == "geoOrthographic" && !this.homePosition) {
      duration += this.animationDuration * 5;
    }
    else {
      duration += this.animationDuration;
    }

    return duration;
  }

  public dispose() {
    this.root.dispose();
  }

  /**
   * starts and stops play animation
   */
  public start() {
    this._isPlaying = true;
    if (this._buttonOutlineAnimation) {
      this._buttonOutlineAnimation.stop();
    }
    this.playButtonOutline.set("forceHidden", true);

    this.playButton.setRaw("active", true);
    this.playButton.states.apply("active");

    this._animateSlider(1);
  }

  protected _animateSlider(position: number, duration?: number, easing?: am5.ease.Easing) {
    if (this._sliderAnimation) {
      this._sliderAnimation.stop();
    }

    if (duration == undefined) {
      duration = this._totalDuration() * (position - this.slider.get("start", 0))
    }

    let start = this.slider.get("start", 0);
    if (start == 1) {
      start = 0;
    }

    this._sliderAnimation = this.slider.animate({
      key: "start",
      from: start,
      to: position,
      duration: duration,
      easing: easing
    });

    this._sliderAnimation.events.on("stopped", () => {
      this.stop();
    })
  }

  public stop() {
    if (this._sliderAnimation) {
      this._sliderAnimation.stop();
      this._sliderAnimation = undefined;
    }
    this.playButton.setRaw("active", false);
    this.playButton.states.applyAnimate("default");
  }

  public clear() {
    this.data = [];
    this.toggleTimeline();
    this._currentStep = 0;
    this.homeStepData = undefined;
    this._continentSteps = {};
    this.polygonSeries.data.setAll([]);
    this.pinSeries.data.setAll([]);
    this.nameSeries.data.setAll([]);
    this._visitedPlaces = [];
    this._firstVisit = {};
    this._createHash();

    setTimeout(() => {
      this._goHome();
    }, 10)
  }


  public replay() {
    this.root.dispose();
    this._currentStep = -1;
    this._appeared = false;
    this._isPlaying = false;
    if (this.data.length > 1) {
      this._replay = true;
    }
    this.slider.set("start", 0);
    this.data = [];
    this._init();
  }

  /***
   * animates to next step
   */
  public next() {
    const count = this.data.length;
    let nextStep = Math.min(count, this._currentStep + 1);

    this.slider.animate({
      key: "start",
      to: Math.min(1, this._stepToPosition(nextStep) + 0.01),
      duration: this.oneStepAnimationDuration,
      easing: this.chart.get("animationEasing")
    });
  }

  protected toggleButtonStates() {
    const position = this.slider.get("start");
    if (position == 0) {
      this.prevButton.set("disabled", true);
      this.prevButton.set("interactive", false);
    }
    else {
      this.prevButton.set("disabled", false);
      this.prevButton.set("interactive", true);
    }

    if (position == 1) {
      this.nextButton.set("disabled", true);
      this.nextButton.set("interactive", false);

    }
    else {
      this.nextButton.set("interactive", true);
      this.nextButton.set("disabled", false);
    }
  }

  /***
   * animates to previous step
   */
  public previous() {
    let nextStep = Math.max(0, this._currentStep - 1);

    let position = this._stepToPosition(nextStep) + 0.01;
    if (nextStep == 0) {
      position = 0;
      this.prevButton.states.applyAnimate("disabled");
      this.prevButton.set("interactive", false);
    }

    this.slider.animate({
      key: "start",
      to: position,
      duration: this.oneStepAnimationDuration,
      easing: this.chart.get("animationEasing")
    });
  }


  public applyTheme(themeName: "dark-yellow" | "dark-green" | "dark-blue" | "dark-gray" | "light-yellow" | "light-green" | "light-blue" | "light-gray") {
    this.themeName = themeName;
    const theme = this.themes[themeName];

    this.stepLabel.set("fill", theme.label);

    this.background.set("fill", theme.background);
    this.waterSeries.mapPolygons.template.set("fill", theme.water);

    this.graticuleSeries.mapLines.template.setAll({
      stroke: theme.graticule,
      strokeOpacity: 0.1
    });
    this.polygonSeries.mapPolygons.template.setAll({
      fill: theme.unvisited,
      stroke: theme.border,
      fillOpacity: 0.8
    });

    this.polygonSeries.mapPolygons.template.states.create("active", {
      fill: theme.visited,
      fillOpacity: 1
    })

    this.polygonSeries.mapPolygons.template.states.lookup("active")!.set("stateAnimationDuration", 1000);

    this.polygonSeries.mapPolygons.template.states.create("hover", { fill: theme.hover })

    am5.array.each(this.polygonSeries.mapPolygons.template.entities, (mapPolygon) => {
      const dataItem = mapPolygon.dataItem as am5.DataItem<am5map.IMapSeriesDataItem>;
      if (dataItem) {
        this._togglePolygon(dataItem.get("id"))
      }
    })

    this.pinTemplate.setAll({

    });

    this.pinTemplate.states.create("active", {
      fill: theme.visited,
      fillOpacity: 1,
      strokeOpacity: 1
    })

    this.pinTemplate.states.create("hover", {
      fill: theme.hover
    })

    const labelColor = theme.label;
    this.visitedLabel.set("fill", labelColor);
    this.comLabel.set("fill", labelColor);
    this.placesLabel.set("fill", labelColor);

    this.stepLabel.states.lookup("default")!.setAll({ opacity: theme.nameLabelOpacity, dx: 0 });
    this.stepLabel.states.create("hidden", { dx: -400, opacity: 0 });

    this.nameLabelTemplate.setAll({
      fill: theme.alternativeLabel
    });

    this.nameLabelTemplate.states.lookup("default")!.set("opacity", theme.nameLabelOpacity);

    this.playButtonOutline.set("stroke", labelColor);

    this.sliderBackground.setAll({
      fill: theme.sliderBackground,
      fillOpacity: theme.sliderOpacity
    });

    const buttons = [this.homeButton, this.zoomControl.plusButton, this.zoomControl.minusButton, this.nextButton, this.prevButton, this.replayButton, this.slider.startGrip, this.playButton, this.saveStepButton, this.saveHomeButton]
    am5.array.each(buttons, (button) => {
      const bg = button.get("background")!;
      bg.set("fill", theme.home)
      bg.states.lookup("default")!.set("fill", theme.home);
      bg.states.create("hover", { fill: theme.hovervisited });
      bg.states.create("down", { fill: theme.hovervisited });
      bg.states.create("active", { fill: theme.hovervisited });
    })

    this.logoPin.set("fill", theme.home);
  }

  public setProjection(projection: "geoEquirectangular" | "geoMercator" | "geoOrthographic" | "geoNaturalEarth1" | "geoEqualEarth" | "geoAlbersUsa", createHash?: boolean) {
    this.projection = projection;
    this.chart.set("projection", am5map[projection]());

    if (projection != "geoOrthographic") {

      if (this.homePosition) {
        this.homePosition.rotationY = 0;
      }

      this.chart.rotate(this.chart.get("rotationX"), 0);
    }

    if (projection == "geoOrthographic") {
      this.chart.setAll({
        panY: "rotateY"
      });
    }
    else if (projection == "geoAlbersUsa") {
      this.chart.setAll({
        panY: "translateY",
        panX: "translateX"
      })
    }
    else {
      this.chart.setAll({
        panY: "translateY"
      })
    }

    if (createHash) {
      this._createHash();
    }
  }

  public toggleWater(enabled: boolean) {
    this.waterSeries.set("forceHidden", !enabled);
    this.waterEnabled = enabled;
    this._createHash();
  }

  public toggleAutoStep(enabled: boolean) {
    this.autoStep = enabled;
    this._createHash();
  }

  public toggleGraticule(enabled: boolean) {
    this.graticuleSeries.set("forceHidden", !enabled);
    this.graticuleEnabled = enabled;
    this._createHash();
  }

  public toggleTimeline(hide?: boolean) {
    if (hide === false || this.data.length > 1) {
      this.sliderContainer.show();
      if (this._buttonOutlineAnimation) {
        this._buttonOutlineAnimation.play();
      }
    }
    else {
      this.sliderContainer.hide(0);

      if (this._buttonOutlineAnimation) {
        this._buttonOutlineAnimation.stop();
      }
    }

    if (hide) {
      this.bottomContainer.set("forceHidden", true);
      this.stepLabel.set("dy", 0);
    }

    this._fixStepLabelPosition();
  }

  protected _fixStepLabelPosition() {
    if (this.data.length > 1) {
      this.stepLabel.set("dy", -45);
      this.stepLabel.set("dx", 0);
      this.stepLabel.states.lookup("default")!.set("dx", 0);
    }
    else {
      this.stepLabel.set("dy", 0);
      this.stepLabel.set("dx", this.stepLabelX);
      this.stepLabel.states.lookup("default")!.set("dx", this.stepLabelX);
    }

    if (this.bottomContainer.get("forceHidden")) {
      this.stepLabel.set("dy", 0);
      this.stepLabel.set("dx", 0);
      this.stepLabel.states.lookup("default")!.set("dx", 0);
    }
  }

  public toggleNames(enabled: boolean) {
    this.namesEnabled = enabled;
    if (!enabled) {
      this.nameSeries.hide();
    }
    else {
      this.nameSeries.show();
    }
    this._createHash();
  }

  public toggleAutoplay(enabled: boolean) {
    this.autoPlay = enabled;
    this._createHash();
  }

  public removePlace(_id: string) {

  }

  public setHome(id?: string) {
    let mapPolygon = this.homePolygon;
    let prevId = this.homeId;
    if (prevId) {
      this.removePlace(prevId);
    }
    this.homeId = id;

    let dataItem = this.polygonSeries.getDataItemById(id as any);
    if (dataItem) {
      mapPolygon = dataItem.get("mapPolygon");
      this.homePolygon = mapPolygon;
      this._addPin(id as string);
      this._addName(id as string);

      var centroid = mapPolygon.geoCentroid();

      if (this.projection == "geoOrthographic" && this.isEditor) {
        if (mapPolygon) {
          var centroid = mapPolygon.geoCentroid();
          if (centroid) {
            this.chart.animate({ key: "rotationX", to: -centroid.longitude, duration: 1500, easing: am5.ease.inOut(am5.ease.cubic) });
            this.chart.animate({ key: "rotationY", to: -centroid.latitude, duration: 1500, easing: am5.ease.inOut(am5.ease.cubic) });

            if (this.homeStepData) {
              this.homeStepData.places = [id];

              const position = this.homeStepData.position;

              if (position) {
                position.rotationX = -centroid.longitude;
                position.rotationY = -centroid.latitude;
              }
            }
          }
        }
      }
      this._togglePolygons();
    }
    else {
      this.homePolygon = undefined;
    }

    this._togglePolygon(prevId);
    this._toggleName(prevId);

    if (this.autoHomeStep) {
      am5.array.each(this.data, (dataItem) => {
        if (dataItem.places.length == 1) {
          if (dataItem.places[0] == id) {
            this.homeStepData = dataItem;
          }
        }
      })
    }
    this._createHash();
  }

  protected _togglePolygon(id?: string) {
    if (id) {
      this._toggleName(id);

      const dataItem = this.polygonSeries.getDataItemById(id);
      if (dataItem) {
        const mapPolygon = dataItem.get("mapPolygon");
        const pinSprite = this._getFirstBulletsSprite(this.pinSeries.getDataItemById(id)) as am5.Graphics;

        if (mapPolygon) {
          let opacity = 1;
          let fill = this.themes[this.themeName].unvisited;
          let active = false;
          let visible = false;

          if (this.homeId == id) {
            fill = this.themes[this.themeName].home;
          }

          if (am5.type.isNumber(this._firstVisit[id]) && this._firstVisit[id] <= this._currentStep) {
            visible = true;
            fill = this.themes[this.themeName].visited;
            if (this.homeId == id) {
              fill = this.themes[this.themeName].home;
            }

            for (let s = this._currentStep; s >= 0; s--) {
              const stepData = this.data[s];

              if (stepData) {

                if (stepData.places.indexOf(id) == -1) {
                  continue;
                }
                else {
                  if (stepData) {
                    const colors = stepData.colors;
                    if (colors) {
                      const color = colors[id];
                      if (color != undefined) {
                        fill = am5.color("#" + color);
                        break;
                      }
                    }
                    if (stepData.color != undefined) {
                      fill = am5.color("#" + stepData.color);
                      break;
                    }
                  }
                }
              }
            }

            active = true;
            const currentStepData = this.data[this._currentStep];
            if (currentStepData) {
              if (currentStepData.places.indexOf(id) == -1) {
                if (this._firstVisit[id] < this._currentStep) {
                  opacity = this.previouslyVisitedOpacity;
                }
              }
            }
          }

          if (this.slider.get("start", 0) == 1) {
            opacity = 1;
          }

          if (this.homeId == id) {
            opacity = 1;
          }

          const hsl = fill.toHSL()
          let l = -0.1;

          if (hsl.l < 0.2) {
            l = 0.1;
          }

          let hover = am5.Color.brighten(fill, l);

          mapPolygon.states.lookup("default")!.setAll({
            fill: fill
          })

          mapPolygon.states.lookup("active")!.setAll({
            fill: fill
          })

          mapPolygon.states.lookup("hover")!.setAll({
            fill: hover
          })

          mapPolygon.set("active", active);

          if (!active) {
            if (this._currentStep == -1) {
              mapPolygon.states.apply("default");
            }
            else {
              mapPolygon.states.applyAnimate("default");
            }
          }
          else {
            mapPolygon.states.applyAnimate("active");
          }

          this.root.events.once("frameended", () => {
            mapPolygon.animate({ key: "opacity", to: opacity, duration: this.animationDuration / 2 });
            mapPolygon.animate({ key: "fill", to: fill, duration: this.animationDuration / 2 });
          })

          if (pinSprite) {
            pinSprite.animate({ key: "fill", to: fill, duration: this.animationDuration / 2 });

            pinSprite.states.lookup("hover")!.setAll({
              fill: hover
            })

            if (visible) {
              pinSprite.animate({ key: "opacity", to: opacity, duration: this.animationDuration / 2 });

              const currentStepData = this.data[this._currentStep];
              if (currentStepData) {
                if (currentStepData.places.indexOf(id) != -1) {
                  pinSprite.appear();
                }
              }
            }
            else {
              pinSprite.hide();

              const polygonDataItem = this.polygonSeries.getDataItemById(id);
              if (polygonDataItem) {
                const mapPolygon = polygonDataItem.get("mapPolygon");
                if (mapPolygon) {
                  mapPolygon.hideTooltip();
                }
              }
            }
          }
        }
      }
    }
  }


  protected _toggleName(id?: string) {
    if (id) {
      const dataItem = this.nameSeries.getDataItemById(id);

      if (dataItem) {
        const nameLabel = this._getFirstBulletsSprite(dataItem) as am5.Label;
        if (nameLabel) {

          const dataContext = dataItem.dataContext as any;
          nameLabel.set("text", dataContext.name);
          let visible = false;

          if (am5.type.isNumber(this._firstVisit[id]) && this._firstVisit[id] == this._currentStep) {
            visible = true;
          }

          if (id == this.homeId) {
            visible = true;
          }

          if (this._currentStep >= this.data.length) {
            visible = false;
          }

          if (visible) {
            nameLabel.show();
          }
          else {
            let duration = this.animationDuration / 2 + Math.random() * this.animationDuration / 3;

            nameLabel.hide(duration);
          }
        }
      }
    }
  }


  // protected //////////////////////////////////////////////////////////////////

  protected _afterNew() {

    if (!this.map) {
      const path = window.location.pathname.replace(/\/*/ig, "");
      if (path !== "" && path !== "view" && path !== "embed" && path !== "video") {
        this.map = path;
      }
    }

    this.worldMap = this.map.match(/world/) ? true : false;

    // Default projection fuzzy logic
    if (this.worldMap) {
      this.waterEnabled = true;
    }
    else if (this.map.match(/(^usa)|(\_usa)/)) {
      this.projection = "geoAlbersUsa";
      this.waterEnabled = false;
    }
    else {
      this.projection = "geoMercator";
      this.waterEnabled = false;
    }

    this._init();
  }

  protected _init() {

    this.root = am5.Root.new(this._div);
    this._firstVisit = {};

    this.exporting = am5plugins_exporting.Exporting.new(this.root, {
      filePrefix: "myVisitedPlaces"
    });

    const logo = this.root._logo;
    if (logo) {
      logo.setAll({
        tooltipX: 0,
        x: am5.p100,
        centerX: am5.p100
      })
    }

    const theme = this.themes[this.themeName];
    const myTheme = am5.Theme.new(this.root);

    myTheme.rule("InterfaceColors").setAll({
      primaryButton: theme.home,
      primaryButtonHover: theme.hovervisited,
      primaryButtonDown: theme.hovervisited,
      primaryButtonActive: theme.hovervisited,
      primaryButtonText: am5.Color.fromHex(0xffffff),
      primaryButtonStroke: am5.Color.fromHex(0xffffff),
      secondaryButton: theme.home,
      secondaryButtonHover: theme.hovervisited,
      secondaryButtonDown: theme.hovervisited,
      secondaryButtonActive: theme.hovervisited,
      secondaryButtonText: am5.Color.fromHex(0xffffff),
      secondaryButtonStroke: am5.Color.fromHex(0xffffff)
    });

    this.buttonTemplate = myTheme.rule("RoundedRectangle", ["button"])
    this.buttonTemplate.setAll({
      cornerRadiusTL: 50,
      cornerRadiusTR: 50,
      cornerRadiusBL: 50,
      cornerRadiusBR: 50
    })

    myTheme.rule("Graphics", ["button", "icon"]).setAll({
      fill: am5.color(0xffffff),
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50
    })

    myTheme.rule("Tooltip", ["tooltip"]).setAll({
      paddingLeft: 8,
      paddingTop: 6,
      paddingBottom: 6,
      paddingRight: 8
    })

    myTheme.rule("Label", ["tooltip"]).setAll({
      fontSize: "0.8em"
    })

    myTheme.rule("PointedRectangle", ["tooltip"]).setAll({
      fillOpacity: 0.7
    })

    myTheme.rule("Button").setAll({
      marginTop: 2,
      centerY: am5.p50,
      centerX: am5.p50,
      marginBottom: 2,
      marginLeft: 2,
      marginRight: 2,
      width: 37,
      height: 37
    })


    myTheme.rule("Button").states.create("disabled", {

    })

    myTheme.rule("RoundedRectangle", ["button"]).states.create("disabled", {
      fill: am5.color(0xdadada)
    })

    myTheme.rule("Button", ["zoomcontrol"]).setAll({
      marginTop: 2,
      marginBottom: 2
    })

    this.root.setThemes([
      am5themes_Animated.new(this.root),
      myTheme
    ]);

    // Get map from the URL
    if (typeof visitedplaces_config != "undefined") {
      if (visitedplaces_config.map) {
        this.map = visitedplaces_config.map;
      }
    }


    // if (!this.map) {
    //   this.map = "world";
    // }


    this.systemTooltipU = this._createSystemTooltip("up");
    this.systemTooltipD = this._createSystemTooltip("down");
    this.systemTooltipH = this._createSystemTooltip("horizontal");

    this.background = am5.Rectangle.new(this.root, {});

    this.chart = this.root.container.children.push(
      am5map.MapChart.new(this.root, {
        animationEasing: am5.ease.out(am5.ease.cubic),
        animationDuration: this.animationDuration,
        zoomStep: this.zoomStep,
        minZoomLevel: this.minZoomLevel,
        panX: "rotateX",
        background: this.background
      })
    );

    this.setProjection(this.projection as any);

    /*this.chart.getTooltip()!.setAll({
      autoTextColor: false
    })
    */

    this.chart.events.on("boundschanged", () => {
      this.stepLabel.set("maxWidth", this.chart.width() - 20);
    })

    this.chart.on("rotationX", () => {
      this._fitLabels0();
    })

    this.chart.on("rotationY", () => {
      this._fitLabels0();
    })

    this.chart.on("zoomLevel", () => {
      this._fitLabels0();
    })

    this.waterSeries = this.chart.series.push(am5map.MapPolygonSeries.new(this.root, {
      affectsBounds: this.worldMap
    }));
    this.waterSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180)
    });
    this.waterSeries.mapPolygons.template.setAll({
      fillOpacity: 1,
      strokeOpacity: 0
    });

    this.graticuleSeries = this.chart.series.push(am5map.GraticuleSeries.new(this.root, {
      affectsBounds: this.worldMap
    }));
    this.graticuleSeries.mapLines.template.setAll({
      strokeOpacity: 1
    });

    // Create polygon series
    this.polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {
        opacity: 0
      })
    );

    // Load GeoJSON
    this._loadGeodata();

    this.polygonSeries.events.on("datavalidated", () => {
      this._handleDataValidated()
    });
    this.polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      strokeWidth: 0.3
    });

    // pin series
    this.pinSeries = this.chart.series.push(am5map.MapPointSeries.new(this.root, {
      longitudeField: "longitude",
      latitudeField: "latitude"
    }));

    this.pinTemplate = am5.Template.new({});

    this.pinSeries.bullets.push(() => {

      const sprite = am5.Circle.new(this.root, {
        radius: this.pinRadius,
        tooltipText: "{name}",
        scale: 1,
      }, this.pinTemplate)

      sprite.states.lookup("default")!.set("scale", 1);

      sprite.states.create("hidden", {
        opacity: 0,
        scale: 3,
        visible: true
      })

      sprite.hide(0);

      sprite.events.on("click", (e) => {
        this._handlePolygonClick(e.target);
      })

      return am5.Bullet.new(this.root, {
        sprite: sprite
      })
    })

    // pin series
    this.nameSeries = this.chart.series.push(am5map.MapPointSeries.new(this.root, {
      longitudeField: "longitude",
      latitudeField: "latitude"
    }));

    this.nameLabelTemplate = am5.Template.new({});

    this.nameSeries.bullets.push(() => {
      const label = am5.Label.new(this.root, {
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        oversizedBehavior: "fit",
        minScale: 0.3,
        opacity: 0,
        fontWeight: this.nameLabelFontWeight as any,
      }, this.nameLabelTemplate)

      label.hide(0);

      return am5.Bullet.new(this.root, {
        sprite: label
      })
    })
    this.nameLabelTemplate.states.create("default", {
      stateAnimationDuration: this.animationDuration / 2,
      opacity: this.themes[this.themeName].nameLabelOpacity,
      stateAnimationEasing: am5.ease.out(am5.ease.cubic),
      dx: 0
    })

    this.nameLabelTemplate.states.create("hidden", {
      stateAnimationDuration: this.animationDuration / 2,
      opacity: 0,
      stateAnimationEasing: am5.ease.out(am5.ease.cubic),
      dx: -100
    })


    this.bottomContainer = this.chart.children.push(am5.Container.new(this.root, {
      y: am5.p100,
      centerX: am5.p50,
      centerY: am5.p100,
      x: am5.p50,
      width: this.sliderWidth,
      paddingLeft: this.sliderPaddingLeft,
      paddingRight: this.sliderPaddingRight,
      paddingBottom: this.sliderPaddingBottom,
      layout: this.root.horizontalLayout,
      tooltip: this.systemTooltipD,
      exportable: false
    }));

    this._setInteractivity();

    // Replay
    const replayIcon = am5.Graphics.new(this.root, {
      themeTags: ["icon"],
      svgPath: "M 9.2 1.9 C 7.3 1.9 5.6 2.5 4.3 3.7 L 3.1 2.5 L 3.1 6.1 L 6.7 6.1 L 5.2 4.6 C 6.2 3.6 7.6 3.1 9.2 3.1 C 12.6 3.1 15.3 5.8 15.3 9.2 C 15.3 12.6 12.6 15.3 9.2 15.3 C 5.8 15.3 3.1 12.6 3.1 9.2 A 0.6 0.6 90 1 0 1.9 9.2 C 1.9 13.3 5.1 16.5 9.2 16.5 C 13.3 16.5 16.5 13.3 16.5 9.2 C 16.5 5.1 13.3 1.9 9.2 1.9 z"
    })

    this.replayButton = this.bottomContainer.children.push(am5.Button.new(this.root, {
      tooltipY: 0,
      tooltipText: "Replay",
      icon: replayIcon
    }));

    const replayBg = this.replayButton.get("background")!
    replayBg.setAll({
      strokeOpacity: 1
    })

    this.replayButton.events.on("click", () => {
      this.replay();
    });

    // timeline slider //////////////////////////////////////////////////////////////
    this.sliderContainer = this.bottomContainer.children.push(am5.Container.new(this.root, {
      width: am5.p100,
      layout: this.root.horizontalLayout,
      visible: false
    }));

    this.sliderContainer.hide();


    // PREVIOUS
    const prevIcon = am5.Graphics.new(this.root, {
      themeTags: ["icon"],

      draw: (display) => {
        display.moveTo(8, -5);
        display.lineTo(0, 0);
        display.lineTo(8, 5);
        display.lineTo(8, -5);

        display.moveTo(0, -5);
        display.lineTo(-2, -5);
        display.lineTo(-2, 5);
        display.lineTo(0, 5);
        display.lineTo(0, -5);
        display.closePath();
      }
    })

    this.prevButton = this.sliderContainer.children.push(am5.Button.new(this.root, {
      tooltipY: 0,
      tooltipText: "Previous step",
      icon: prevIcon
    }));

    const prevBg = this.prevButton.get("background")!
    prevBg.setAll({
      strokeOpacity: 1
    })

    this.prevButton.events.on("click", () => {
      this.previous();
    });

    // NEXT
    const nextIcon = am5.Graphics.new(this.root, {
      themeTags: ["icon"],

      draw: (display) => {
        display.moveTo(0, -5);
        display.lineTo(8, 0);
        display.lineTo(0, 5);
        display.lineTo(0, -5);
        display.moveTo(8, -5);
        display.lineTo(10, -5);
        display.lineTo(10, 5);
        display.lineTo(8, 5);
        display.lineTo(8, -5);
        display.closePath();
      }

    })

    this.nextButton = this.sliderContainer.children.push(am5.Button.new(this.root, {
      tooltipText: "Next step",
      tooltipY: 0,
      icon: nextIcon
    }));

    const nextBg = this.nextButton.get("background")!;
    nextBg.setAll({
      strokeOpacity: 1
    })

    this.nextButton.events.on("click", () => {
      this.next();
    });

    const playIcon = am5.Graphics.new(this.root, {
      themeTags: ["icon"]
    })

    // PLAY
    this.playButton = this.sliderContainer.children.push(am5.Button.new(this.root, {
      themeTags: ["play"],
      tooltipY: 0,
      tooltipText: "Play/Stop",
      icon: playIcon,
      toggleKey: "none"
    }));


    this.playButton.get("background")!.setAll({
      strokeOpacity: 1,
      strokeWidth: 1
    })

    this.playButton.events.on("click", () => {
      if (!this._sliderAnimation) {
        this.start();
      }
      else {
        this.stop();
      }
    });


    this.slider = this.sliderContainer.children.push(am5.Slider.new(this.root, {
      orientation: "horizontal",
      start: 0,
      marginLeft: 40,
      centerY: am5.p50
    }));

    this.sliderBackground = this.slider.get("background")!;

    var labelColor = this.themes[this.themeName].label;

    this.stepLabel = this.chart.children.push(am5.Label.new(this.root, {
      text: "",
      oversizedBehavior: "fit",
      centerX: 0,
      fontSize: "2em",
      y: am5.p100,
      paddingLeft: 17,
      paddingBottom: 15,
      opacity: 0.5,
      centerY: am5.p100,
      fill: labelColor
    }))

    this.slider.startGrip.setAll({
      tooltipY: 0,
      tooltipText: "Drag to change position"
    })

    const sliderGripIcon = this.slider.startGrip.get("icon")!;
    sliderGripIcon.setAll({
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50
    })

    this.slider.events.on("rangechanged", () => {
      if (this._appeared) {
        this._handleSlider();
      }
    });

    this.zoomControl = this.chart.set("zoomControl", am5map.ZoomControl.new(this.root, {
      tooltip: this.systemTooltipH,
      exportable: false,
      y: 0,
      centerY: 0,
      paddingTop: 15,
      paddingRight: 15
    }));

    this.zoomControl.plusButton.setAll({
      tooltipX: 0,
      tooltipText: "Zoom in"
    })

    this.zoomControl.minusButton.setAll({
      tooltipX: 0,
      tooltipText: "Zoom out"
    })

    this.homeButton = this.zoomControl.children.moveValue(am5.Button.new(this.root, {
      tooltipText: "Zoom out to home view",
      tooltipX: 0,
      icon: am5.Graphics.new(this.root, {
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        svgPath: "M11.5 2.9C11.2 2.9 10.9 3 10.6 3.2L3.2 9.4C2.9 9.7 2.8 10.1 3 10.4 3.2 10.8 3.7 10.8 4.1 10.6L11.4 4.4C11.5 4.4 11.6 4.4 11.6 4.4L19 10.6C19.1 10.7 19.3 10.7 19.4 10.7 19.7 10.7 19.9 10.6 20.1 10.3 20.2 10 20.1 9.6 19.9 9.4L18.7 8.5 18.7 5.4C18.7 5 18.4 4.7 18 4.7L17.3 4.7C16.9 4.7 16.6 5 16.6 5.4L16.6 6.7 12.4 3.2C12.2 3 11.8 2.9 11.5 2.9zM11.5 5.8 4.3 11.8 4.3 16.9C4.3 17.9 5.1 18.7 6.1 18.7L9 18.7C9.6 18.7 10.1 18.2 10.1 17.6L10.1 14C10.1 13.6 10.4 13.3 10.8 13.3L12.2 13.3C12.6 13.3 13 13.6 13 14L13 17.6C13 18.2 13.4 18.7 14 18.7L16.9 18.7C17.9 18.7 18.7 17.9 18.7 16.9L18.7 11.8 11.5 5.8z",
        fill: am5.color(0xffffff)
      })
    }), 0);

    this.homeButton.events.on("click", () => {
      this._goHome();
    });


    // save button only in edit mode
    this.saveStepButton = this.zoomControl.children.moveValue(am5.Button.new(this.root, {
      tooltipX: 0,
      width: 37,
      height: 37,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      visible: false,
      marginBottom: 30,
      tooltipText: "Save current position ant zoom level",
      icon: am5.Graphics.new(this.root, {
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        svgPath: "M8.8 1C5.3 1 2.4 3.9 2.4 7.4 2.4 8.4 2.6 9.5 3.2 10.4L8.5 20C8.6 20.1 8.6 20.2 8.8 20.2 9 20.2 9 20.1 9.1 20L10.9 16.8C11 16.7 11 16.5 10.9 16.4 10.9 16.3 10.7 16.2 10.6 16.2L9.8 16.2C8.8 16.2 8 15.4 8 14.4L8 10.8C8 10.6 7.9 10.4 7.8 10.4 6.5 10 5.6 8.8 5.6 7.4 5.6 5.6 7 4.2 8.8 4.2 10.2 4.2 11.4 5.1 11.8 6.4 11.8 6.5 12 6.6 12.2 6.6L14.6 6.6C14.8 6.6 14.9 6.5 15 6.4 15 6.4 15.1 6.2 15 6.1 14.5 3.2 11.8 1 8.8 1zM9.8 7.4C9.3 7.4 8.8 7.9 8.8 8.4L8.8 14.4C8.8 14.9 9.3 15.4 9.8 15.4L15.8 15.4C16.3 15.4 16.8 14.9 16.8 14.4L16.8 8.6C16.8 8.5 16.7 8.4 16.7 8.3L15.9 7.5C15.6 7.2 15.2 7.5 15.2 7.8L15.2 9.6C15.2 10.2 14.7 10.6 14.2 10.6L11.4 10.6C10.9 10.6 10.4 10.2 10.4 9.6L10.4 7.8C10.4 7.6 10.2 7.4 10 7.4L9.8 7.4zM14 8.2C13.8 8.2 13.6 8.4 13.6 8.6L13.6 9.4C13.6 9.6 13.8 9.8 14 9.8 14.2 9.8 14.4 9.6 14.4 9.4L14.4 8.6C14.4 8.4 14.2 8.2 14 8.2zM10.8 11.4 14.8 11.4C15 11.4 15.2 11.6 15.2 11.8L15.2 14.6 10.4 14.6 10.4 11.8C10.4 11.6 10.6 11.4 10.8 11.4z",
        fill: am5.color(0xffffff)
      })
    }), 0);

    this.saveHomeButton = this.zoomControl.children.moveValue(am5.Button.new(this.root, {
      tooltipX: 0,
      width: 37,
      height: 37,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      visible: false,
      tooltipText: "Save home position ant zoom level",
      icon: am5.Graphics.new(this.root, {
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        svgPath: "M12.8 11.9C12.4 11.9 12 12.4 12 12.8V17.9c0 .4.4.8.8.8H17.9c.4 0 .8-.4.8-.8V12.9c0-.1-.1-.2-.1-.3l-.7-.7c-.3-.3-.6 0-.6.3v1.5c0 .5-.4.8-.8.8h-2.4c-.4 0-.8-.3-.8-.8V12.3c0-.2-.2-.3-.3-.3H12.8zM16.4 12.6c-.2 0-.3.2-.3.3v.7c0 .2.2.3.3.3s.3-.2.3-.3V12.9C16.7 12.8 16.6 12.6 16.4 12.6zM13.7 15.3h3.4c.2 0 .3.2.3.3v2.4h-4.1v-2.4C13.4 15.5 13.5 15.3 13.7 15.3zM11.5 2.9C11.2 2.9 10.9 3 10.6 3.2L3.2 9.4C2.9 9.7 2.8 10.1 3 10.4 3.2 10.8 3.7 10.8 4.1 10.6L11.4 4.4C11.5 4.4 11.6 4.4 11.6 4.4L19 10.6C19.1 10.7 19.3 10.7 19.4 10.7 19.7 10.7 19.9 10.6 20.1 10.3 20.2 10 20.1 9.6 19.9 9.4L18.7 8.5 18.7 5.4C18.7 5 18.4 4.7 18 4.7L17.3 4.7C16.9 4.7 16.6 5 16.6 5.4L16.6 6.7 12.4 3.2C12.2 3 11.8 2.9 11.5 2.9zM11.5 5.8 4.3 11.8 4.3 16.9C4.3 17.9 5.1 18.7 6.1 18.7L9 18.7C9.6 18.7 10.1 18.2 10.1 17.6Q10.1 14.8 10.1 12C10.1 10.4 10.1 10.4 11.3 10.4L17 10.4 11.5 5.8z",
        fill: am5.color(0xffffff)
      })
    }), 0);


    this.polygonSeries.mapPolygons.template.setAll({
      toggleKey: "active"
    });

    var iconHome = am5.Graphics.new(this.root, {
      strokeOpacity: 0.7,
      strokeWidth: 0.5,
      draw: (display) => {
        let r = 5;
        display.moveTo(-r, -r);
        display.lineTo(r, r);
        display.moveTo(-r, r);
        display.lineTo(r, -r);
        display.moveTo(0, 0);
      },
      themeTags: ["icon"],
      fill: this.root.interfaceColors.get("background"),
      fillOpacity: 0.1,
      stroke: this.root.interfaceColors.get("background")
    })

    // reset home button only in edit mode
    const resetHomePointButton = this.zoomControl.children.push(am5.Button.new(this.root, {
      tooltip: this.systemTooltipH,
      tooltipText: "Reset home position ant zoom level",
      tooltipX: 0,
      position: "absolute",
      x: -37,
      y: 35,
      width: 25,
      height: 25,
      visible: false,
      icon: iconHome
    }))

    this.resetHomePointButton = resetHomePointButton;

    const resetHomeBackground = resetHomePointButton.get("background")! as am5.RoundedRectangle;

    resetHomeBackground.setAll({
      fillOpacity: 0,
      cornerRadiusTR: 50,
      cornerRadiusTL: 50,
      cornerRadiusBR: 50,
      cornerRadiusBL: 50
    })

    resetHomeBackground.states.create("hover", {
      fillOpacity: 0.6
    })

    var iconStep = am5.Graphics.new(this.root, {
      strokeOpacity: 0.7,
      strokeWidth: 0.5,
      draw: (display) => {
        let r = 5;
        display.moveTo(-r, -r);
        display.lineTo(r, r);
        display.moveTo(-r, r);
        display.lineTo(r, -r);
        display.moveTo(0, 0);
      },
      themeTags: ["icon"],
      fill: this.root.interfaceColors.get("background"),
      fillOpacity: 0.1,
      stroke: this.root.interfaceColors.get("background")
    })


    // reset home button only in edit mode
    const resetStepPointButton = this.zoomControl.children.push(am5.Button.new(this.root, {
      tooltip: this.systemTooltipH,
      tooltipText: "Reset step position ant zoom level",
      tooltipX: 0,
      position: "absolute",
      x: -37,
      y: 35 + 42,
      width: 25,
      height: 25,
      visible: false,
      icon: iconStep
    }))

    resetStepPointButton.events.on("click", () => {
      this.resetStepPoint();
    })

    resetHomePointButton.events.on("click", () => {
      this.resetHomePoint();
    })

    this.resetStepPointButton = resetStepPointButton;

    const resetStepbackground = resetStepPointButton.get("background")! as am5.RoundedRectangle;

    resetStepbackground.setAll({
      fillOpacity: 0,
      cornerRadiusTR: 50,
      cornerRadiusTL: 50,
      cornerRadiusBR: 50,
      cornerRadiusBL: 50
    })

    resetStepbackground.states.create("hover", {
      fillOpacity: 0.6
    })

    this.logoContainer = this.chart.children.push(am5.Container.new(this.root, {
      layout: this.root.horizontalLayout,
      paddingLeft: 16,
      paddingTop: 3,
      background: am5.Rectangle.new(this.root, {
        fillOpacity: 0,
        fill: labelColor
      }),
      tooltip: this.systemTooltipU,
      tooltipY: am5.p100,
      tooltipText: "Create your own visited places map",
      setStateOnChildren: true,
      cursorOverStyle: "pointer"
    }));

    try {
      if ((window.top && window.top != window.self && window.top.location.host == window.self.location.host) ? true : false) {
        this.logoContainer.set("forceHidden", true);
      }
    }
    catch (e) {
      // Nothing
    }

    this.logoContainer.states.create("hover", {});

    this.logoContainer.events.on("click", () => {
      window.open("https://visitedplaces.com/")
    });

    this.visitedLabel = this.logoContainer.children.push(am5.Label.new(this.root, {
      text: "visited",
      fillOpacity: 0.5,
      fontSize: "2em",
      paddingRight: 0,
      paddingLeft: 0,
      fill: labelColor
    }))

    this.visitedLabel.states.create("hover", { fillOpacity: 1 });

    this.placesLabel = this.logoContainer.children.push(am5.Label.new(this.root, {
      text: "places",
      fillOpacity: 0.9,
      fontSize: "2em",
      paddingRight: 0,
      paddingLeft: 0,
      fill: labelColor
    }))

    this.placesLabel.states.create("hover", { fillOpacity: 0.5 });


    this.logoPin = this.logoContainer.children.push(am5.Graphics.new(this.root, {
      svgPath: "M4.6 1.6c-.3.3-.4.6-.4 1 0 .8.6 1.5 1.5 1.5h.8l-.9 6.6c-1.5.4-2.7 1.4-3.3 2.8-.4.8.2 2 1.3 2h5.4l.2 10c0 .2.1.3.3.3s.3-.1.3-.3l.2-10h5.4c1 0 1.7-1 1.3-2-.6-1.4-1.8-2.5-3.3-2.8l-.9-6.6h.8c.8 0 1.5-.7 1.5-1.5 0-.8-.7-1.5-1.5-1.5h-7.7C5.3 1.1 4.9 1.3 4.6 1.6L4.6 1.6z",
      fill: this.themes[this.themeName].home,
      scale: 0.75,
      marginLeft: 2,
      marginRight: 1,
      y: 30,
      dy: 0
    }))

    this.logoPin.states.create("hover", {
      dy: -15
    })

    this.comLabel = this.logoContainer.children.push(am5.Label.new(this.root, {
      text: "com",
      fillOpacity: 0.5,
      fontSize: "2em",
      paddingRight: 0,
      paddingLeft: 0,
      fill: labelColor
    }))

    this.comLabel.states.create("hover", { fillOpacity: 1 });

    this.playButtonOutline = this.playButton.children.push(am5.Circle.new(this.root, {
      radius: 20,
      visible: false,
      isMeasured: false,
      strokeWidth: 3,
      strokeOpacity: 0.5,
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50
    }))

    this.applyTheme(this.themeName);

    this._isPlaying = true;
    const promise = this.chart.appear(this.animationDuration);
    promise.then(() => {
      this._appeared = true;

      if ((this.autoPlay && !this.isEditor) || this._replay) {
        this.start();
        this._replay = false;
      }
      else {
        if (!this._sliderAnimation) {
          this._animateSlider(this._stepToPosition(1) * 0.5, this.animationDuration, this.chart.get("animationEasing"));
          this._showPlayAnimation();
        }
      }
    })
    this._parseHash();
  }

  public resetHomePoint() {

  }

  public resetStepPoint() {

  }

  protected _loadGeodata() {
    if (this.map) {
      am5.net.load("https://cdn.amcharts.com/lib/5/geodata/json/" + this.map.replace(/\_/g, "/") + "Low.json").then((res) => {
        if (res && res.response) {
          var geodata = am5.JSONParser.parse(res.response);
          this.polygonSeries.set("geoJSON", geodata);
        }
      });
    }
  }

  protected _createSystemTooltip(pointerOrientation: "up" | "down" | "horizontal"): am5.Tooltip {
    const tooltip = am5.Tooltip.new(this.root, {
      getFillFromSprite: false,
      autoTextColor: false,
      getStrokeFromSprite: false,
      pointerOrientation: pointerOrientation
    });

    tooltip.label.setAll({
      fontSize: 10,
      fill: am5.color(0x000000)
    })

    const tooltipBackgroundV = tooltip.get("background") as am5.PointedRectangle;
    tooltipBackgroundV!.setAll({
      cornerRadius: 0,
      fill: am5.color(0xffffff),
      strokeOpacity: 0.2,
      stroke: am5.color(0x000000),
      fillOpacity: 0.8
    })
    return tooltip;
  }

  public getConfig(): { [index: string]: any } {
    const config: { [index: string]: any } = {
      map: this.map,
      projection: this.projection,
      theme: this.themeName,
      water: (this.waterEnabled ? 1 : 0),
      graticule: (this.graticuleEnabled ? 1 : 0),
      names: (this.namesEnabled ? 1 : 0),
      duration: this.duration,
      slider: this.sliderPosition,
      autoplay: (this.autoPlay ? 1 : 0),
      autozoom: this.autoZoom,
      data: this.data
    };
    if (am5.type.isNumber(this.width) && this.width > 0) {
      config["width"] = this.width;
    }
    if (am5.type.isNumber(this.height) && this.height > 0) {
      config["height"] = this.height;
    }
    if (this.homeId) {
      config["home"] = this.homeId;
    }
    return config;
  }


  protected _parseHash() {

    this._visitedPlaces = [];
    let string = window.location.search;

    const bits = string.replace("?", "").split("&");
    const config: { [index: string]: string } = {};
    am5.array.each(bits, (bit) => {
      const nv = bit.split("=");
      config[nv[0]] = nv[1];
    });

    if (typeof visitedplaces_config != "undefined") {
      am5.object.each(visitedplaces_config, (name: any, value: any) => {
        config[name] = value;

        if (name == "data") {
          this.data = value;
          delete config["places"]; // disregard hash if data is present from config
          this._visitedPlaces = [];
          am5.array.each(this.data, (step, index) => {
            am5.array.each(step.places, (place) => {
              if (this._visitedPlaces.indexOf(place) === -1) {
                this._visitedPlaces.push(place);
                if (this._firstVisit[place] == undefined) {
                  this._firstVisit[place] = index;
                }
              }
            })
          });
        }
      });
    }

    if (!this.map) {
      this.map = config.map || "world";
      this._loadGeodata();
    }

    am5.object.each(config, (name: any, value: string) => {
      switch (name) {
        case "map":
          this.map = value as string;
          break;
        case "theme":
          this.themeName = value as any;
          break;
        case "position":
          const positions = value.split("_")
          this.homePosition = {
            zoomLevel: Number(positions[0]),
            geoPoint: {
              longitude: Number(positions[1]),
              latitude: Number(positions[2])
            },
            rotationX: Number(positions[3]),
            rotationY: Number(positions[4])
          };
          break;
        case "water":
          this.waterEnabled = Boolean(Number(value));
          this.toggleWater(this.waterEnabled);
          break;
        case "width":
          this.width = Number(value);
          break;
        case "height":
          this.height = Number(value);
          break;
        case "graticule":
          this.graticuleEnabled = Boolean(Number(value));
          this.toggleGraticule(this.graticuleEnabled);
          break;
        case "autoplay":
          this.autoPlay = Boolean(Number(value));
          break;
        case "autozoom":
          this.autoZoom = value as any;
          break;
        case "projection":
          this.setProjection(value as any);
          break;
        case "duration":
          this.duration = Number(value);
          break;
        case "autostep":
          this.autoStep = Boolean(Number(value));
          this.toggleAutoStep(this.autoStep);
          break;
        case "placeduration":
          this.placeDuration = Number(value);
          break;
        case "slider":
          this.sliderPosition = Number(value)
          this.slider.set("start", this.sliderPosition);
          break;
        case "places":
          value = value.replace("~~~~~", "§§§").replace("**", "©©©");
          const datas = value.split("*");
          let step = 0;
          am5.array.each(datas, (data) => {

            // temporarily encode escaped ~ and *
            const sdp = data.split("~");
            const text = decodeURIComponent(sdp[0].replace("§§§", "~").replace("©©©", "*"));

            const ids = sdp[1].split("_");

            const positions = sdp[2];
            const position = {} as any;
            const color = sdp[3];

            if (positions) {
              const pos = positions.split("_");
              position.zoomLevel = Number(pos[0]);
              position.geoPoint = { longitude: Number(pos[1]), latitude: Number(pos[2]) };
              position.rotationX = Number(pos[3]);
              position.rotationY = Number(pos[4]);
            }

            const obj: any = { colors: {}, places: [] };

            if (color) {
              obj.color = color;
            }

            if (sdp[1]) {
              am5.array.each(ids, (idColor, i) => {
                const idColorArr = idColor.split(".")
                const id = idColorArr[0];
                const color = idColorArr[1];

                ids[i] = id;

                if (color != undefined) {
                  obj.colors[id] = color;
                }

                if (this._firstVisit[id] == undefined) {
                  this._firstVisit[id] = step;
                }
                this._visitedPlaces.push(id);

                if (this.worldMap) {
                  let continent = am5geodata_data_countries2[id] ? am5geodata_data_countries2[id].continent : "Other";
                  if (continent == "Antarctica") {
                    continent = "Other";
                  }
                  if (id != this.homeId) {
                    this._continentSteps[continent] = obj;
                  }
                }
              });
              if (ids && ids.length > 0) {
                obj.places = ids;
              }
            }

            if (text) {
              obj.text = text;
            }
            if (positions) {
              obj.position = position;
            }

            this.data.push(obj);

            step++;
          })
          break;

        case "home":
          this.setHome(value);
          break;
      }
    });

    if (this.homeId) {
      this.createHomeStep(this.homeId);
    }

    this.toggleTimeline();
    this.applyTheme(this.themeName);
  }

  public createHomeStep(_id: string) {

  }

  protected _showPlayAnimation() {
    this.playButtonOutline.show();
    this._buttonOutlineAnimation = this.playButtonOutline.animate({
      key: "scale",
      to: 2,
      duration: 2000,
      easing:
        am5.ease.yoyo(am5.ease.cubic),
      loops: Infinity
    });
  }

  protected _totalDuration(): number {
    let totalDuration = 0;
    am5.array.each(this.data, (data) => {
      const placesCount = data.places.length;
      const stepDuration = this.duration + placesCount * this.placeDuration + this.animationDuration * 0.8;
      totalDuration += stepDuration;
    })

    return totalDuration;
  }

  protected _positionToStep(position: number): number {
    let totalDuration = 0;
    let stepStart: Array<number> = [];
    let stepEnd: Array<number> = [];
    let step = 0;

    am5.array.each(this.data, (data) => {
      const placesCount = data.places.length;

      const stepDuration = this.duration + placesCount * this.placeDuration + this.animationDuration * 0.8;
      stepStart[step] = totalDuration;

      this._stepTimes[step] = totalDuration;

      totalDuration += stepDuration;
      stepEnd[step] = totalDuration;
      step++;

      this._stepTimes[step] = totalDuration;
    })

    let currentTime = totalDuration * position;
    let currentStep = 0;

    step = 0;

    am5.array.each(stepStart, (start) => {
      if (currentTime >= start) {
        currentStep = step;
      }
      step++;
    })

    if (currentTime >= totalDuration) {
      currentStep = this.data.length;
    }

    return currentStep;
  }

  protected _stepToPosition(step: number) {
    this._positionToStep(0);
    return this._stepTimes[step] / this._totalDuration() + 0.001;
  }

  protected _handleSlider() {
    const position = this.slider.get("start", 0);
    let count = this.data.length;
    let step = this._positionToStep(position);

    step = am5.math.fitToRange(step, 0, count);

    if (step != this._currentStep) {
      this._setStep(step);
    }

    if (position == 1) {
      this._handleSliderEnd();
    }

    setTimeout(() => {
      this.toggleButtonStates();
    }, 10)
  }

  protected _goHome(duration?: number) {
    if (duration == null) {
      duration = this.animationDuration;
    }
    const homePosition = this.homePosition;
    if (homePosition) {
      let rotationX: number | undefined = homePosition.rotationX;
      let rotationY: number | undefined = homePosition.rotationY;

      if (this.projection == "geoAlbersUsa") {
        rotationX = undefined;
        rotationY = undefined;
      }

      if (this.projection != "geoOrthographic") {
        rotationY = 0;
      }

      this.chart.zoomToGeoPoint(homePosition.geoPoint, homePosition.zoomLevel, true, duration, rotationX, rotationY)
    }
    else {
      this.chart.rotate(0, 0);
      this.chart.goHome();
    }
  }


  protected _handleSliderEnd() {
    this.playButton.set("active", false);
    this.playButton.states.applyAnimate("default");

    if (this.data.length > 1) {
      this.stepLabel.hide();
    }

    let animationDuration = this.animationDuration;
    let endDuration = animationDuration;

    if (this.homePosition) {
      this._goHome();
    }
    else if (this.projection == "geoOrthographic") {
      this.chart.animate({ key: "rotationY", to: -10, duration: animationDuration, easing: am5.ease.out(am5.ease.sine) });
      this.chart.animate({ key: "rotationX", to: this.chart.get("rotationX", 0) + 360, loops: Infinity, duration: animationDuration * 4, easing: am5.ease.linear });
      this.chart.animate({ key: "translateX", to: this.chart.width() / 2, duration: animationDuration, easing: am5.ease.out(am5.ease.sine) });
      this.chart.animate({ key: "translateY", to: this.chart.height() / 2, duration: animationDuration, easing: am5.ease.out(am5.ease.sine) });
      this.chart.animate({ key: "zoomLevel", to: .9, duration: animationDuration * 2, easing: am5.ease.out(am5.ease.sine) });
      endDuration = this.animationDuration * 5;
    }
    else {
      this._goHome();
    }

    const logoAnimation = this.logoPin.animate({
      key: "marginBottom", from: 0, to: 1, duration: endDuration
    })

    logoAnimation.events.on("stopped", () => {
      this._isPlaying = false;
    })

    this._toggleName(this.homeId);
  }


  protected _togglePolygons(zoomOut?: boolean) {
    const data = this.data[this._currentStep];
    let duration = this.animationDuration;

    am5.array.each(this._visitedPlaces, (id) => {
      this._addPin(id);
    })

    let text = "";
    let position;

    if (data) {
      am5.array.each(data.places, (id) => {
        this._addName(id);
      })

      text = data.text || "";
      position = data.position;
    }
    if (this.data.length == 1) {
      let txt = this.data[0].text;
      if (txt) {
        text = txt;
      }
    }

    if (text != this.stepLabel.get("text")) {
      const promise = this.stepLabel.hide();

      promise.then(() => {
        this._fixStepLabelPosition();
        this.stepLabel.set("text", text);
        this.stepLabel.appear(duration / 3);
      })
    }

    if (zoomOut) {
      this._goHome();
    }
    else {
      if (position) {
        const geoPoint = position.geoPoint;
        const zoomLevel = position.zoomLevel;

        let rotationX: number | undefined = position.rotationX;
        let rotationY: number | undefined = position.rotationY;

        if (this.projection == "geoAlbersUsa") {
          rotationX = undefined;
          rotationY = undefined;
        }
        if (this.projection != "geoOrthographic") {
          rotationY = 0;
        }

        this.chart.zoomToGeoPoint(geoPoint, zoomLevel, true, undefined, rotationX, rotationY);
      }
      else if (this.autoZoom != "none") {
        if (data) {
          let arr = data.places;
          if (this.autoZoom == "all") {
            arr = [];
            am5.array.each(this._visitedPlaces, (id) => {
              if (this._firstVisit[id] <= this._currentStep) {
                arr.push(id);
              }
            })
          }
          this._autoZoom(arr);
        }
      }
    }

    const logoAnimation = this.logoPin.animate({
      key: "marginTop", from: 0, to: 1, duration: this.animationDuration * 0.8
    })

    this._stepDisposers.push(logoAnimation.events.on("stopped", () => {
      this._realTogglePolygons();
    }))
  }

  protected _autoZoom(arr: Array<string>) {
    let left: number | undefined;
    let right: number | undefined;
    let top: number | undefined;
    let bottom: number | undefined;

    const geometryColection: any = { type: "GeometryCollection", geometries: [] as any };

    am5.array.each(arr, (id) => {
      const dataItem = this.polygonSeries.getDataItemById(id);
      if (dataItem) {
        const geometry = dataItem.get("geometry");
        if (geometry) {
          geometryColection.geometries.push(geometry);
          const bounds = am5map.getGeoBounds(geometry);
          if (left == null || left > bounds.left) {
            left = bounds.left;
          }
          if (right == null || right < bounds.right) {
            right = bounds.right;
          }
          if (top == null || top < bounds.top) {
            top = bounds.top;
          }
          if (bottom == null || bottom > bounds.bottom) {
            bottom = bounds.bottom;
          }
        }
      }
    })
    if (left != undefined && right != undefined && top != undefined && bottom != undefined) {
      const centroid = am5map.getGeoCentroid(geometryColection);
      let rotationX: number | undefined = -centroid.longitude;
      let rotationY: number | undefined = -centroid.latitude;
      if (this.projection != "geoOrthographic") {
        rotationY = 0;
        rotationX = 0;
      }

      if (this.projection == "geoAlbersUsa") {
        rotationX = undefined;
        rotationY = undefined;
      }

      this.chart.zoomToGeoBounds({ left, right, top, bottom }, undefined, rotationX, rotationY);
    }
  }

  protected _toggleButtons() {

  }


  protected _realTogglePolygons() {

    this._togglePolygon(this.homeId);
    this._toggleName(this.homeId);

    const data = this.data[this._currentStep];

    am5.array.each(this._visitedPlaces, (id) => {
      if (!data || data.places.indexOf(id) == -1) {
        this._togglePolygon(id);
      }
    })

    if (data) {
      let i = 1;

      let interval = Math.max(1, this.placeDuration);

      am5.array.each(data.places, (id) => {
        const dataItem = this.polygonSeries.getDataItemById(id);
        if (dataItem) {
          const mapPolygon = dataItem.get("mapPolygon");
          if (mapPolygon) {

            let duration = interval * i;

            const animation = mapPolygon.animate({ key: "marginTop", from: 0, to: 100, duration: duration });
            this._stepDisposers.push(animation.events.on("stopped", () => {
              this._togglePolygon(id);
            }))
          }
        }
        i++;
      })
    }

    this._fitLabels0();
  }


  protected _handleDataValidated() {
    this.waterSeries.mapPolygons.template.set("fillOpacity", 0.99999) // hack for video bug
    this.setHome(this.homeId);
    this._setStep(this._currentStep);
    this.polygonSeries.appear();
    this._goHome(0);
  }


  protected _measurePolygon(mapPolygon: am5map.MapPolygon) {
    const geometry = mapPolygon.get("geometry");
    let area = 0;
    if (geometry) {
      area = am5map.getGeoArea(geometry);
    }

    const dataItem = mapPolygon.dataItem as am5.DataItem<am5map.IMapPolygonSeriesDataItem>;
    if (dataItem) {
      const id = dataItem.get("id");
      if (id) {
        this._polygonAreas[id] = area;
      }
    }
  }

  protected _addName(id: string) {
    const nameDataItem = this.nameSeries.getDataItemById(id);
    const polygonDataItem = this.polygonSeries.getDataItemById(id);

    if (polygonDataItem) {
      const mapPolygon = polygonDataItem.get("mapPolygon");

      const dataContext = polygonDataItem.dataContext as any;
      const center = mapPolygon.visualCentroid();

      if (!nameDataItem) {
        this.nameSeries.data.push({
          id: id,
          name: dataContext.name,
          latitude: center.latitude,
          longitude: center.longitude
        })
      }
    }
  }

  protected _addPin(id: string) {
    const pinDataItem = this.pinSeries.getDataItemById(id);
    const polygonDataItem = this.polygonSeries.getDataItemById(id);

    if (polygonDataItem) {
      const mapPolygon = polygonDataItem.get("mapPolygon");
      this._measurePolygon(mapPolygon);
      if (this._polygonAreas[id] < this.pinThreshold) {
        const dataContext = polygonDataItem.dataContext as any;
        const center = mapPolygon.geoCentroid();

        if (!pinDataItem) {
          this.pinSeries.data.push({
            id: id,
            name: dataContext.name,
            latitude: center.latitude,
            longitude: center.longitude
          })
        }
      }
    }
  }


  protected _handlePolygonClick(_sprite: am5.Graphics) {

    // void
  }

  protected _createHash() {
    // void
  }

  protected _getFirstBulletsSprite(dataItem?: am5.DataItem<am5map.IMapSeriesDataItem>): am5.Sprite | undefined {
    if (dataItem) {
      const bullets = dataItem.bullets;
      if (bullets) {
        const bullet = bullets[0];

        if (bullet) {
          return bullet.get("sprite");
        }
      }
    }
  }

  protected _fitLabels0() {
    if (this._fitLabelsDp) {
      this._fitLabelsDp.dispose();
    }
    this._fitLabelsDp = this.root.events.once("frameended", () => {
      this._fitLabels()
    })
  }

  protected _fitLabels() {
    am5.array.each(this.nameSeries.dataItems, (dataItem) => {
      const label = this._getFirstBulletsSprite(dataItem) as am5.Label;
      if (label) {
        const id = dataItem.get("id");
        if (id) {
          const polygonDataItem = this.polygonSeries.getDataItemById(id);
          if (polygonDataItem) {
            const mapPolygon = polygonDataItem.get("mapPolygon");
            this._fitLabel(label, mapPolygon);
          }
        }
      }
    })
  }

  protected _fitLabel(label: am5.Label, mapPolygon: am5map.MapPolygon) {

    const geometry = mapPolygon.get("geometry");
    if (geometry) {
      const geoPath = this.chart.getPrivate("geoPath");
      const bounds = geoPath.bounds(geometry);

      const left = bounds[0][0];
      const right = bounds[1][0];
      label.set("maxWidth", Math.abs(right - left))
    }
  }

  protected _setInteractivity() {

  }
}
